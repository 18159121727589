// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const EditFareMedia = () => {

  const currentLanguageCode = localStorage.getItem('EASY_MOBILITY_LANGUAGE') || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const { id } = useParams();
  const query = new URLSearchParams(history.location.search);
  const came_from = query.get('came_from'); // Get "id" parameter
  console.log(came_from);
  
  // State variables
  const [merchent_ID, setMerchantId] = useState(null);

  const [merchants, setMerchants] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const [uid, setuid] = useState('');
  const [serial_num, setserial_num] = useState('');
  const [fare_media_type_id, setfare_media_type_id] = useState('');
  const [init_balance, setinit_balance] = useState(0);
  const [fare_media_issuer_id, setfare_media_issuer_id] = useState('');
  const [organisation_id, setorganisation_id] = useState('');
  const [selling_price, setselling_price] = useState(0);
  const [blacklist_note, setblacklist_note] = useState('');
  const [is_blacklist, setis_blacklist] = useState(null);

  const [fareMediaTypes, setFareMediaTypes] = useState([]);
  const [fareMediaIssuers, setFareMediaIssuers] = useState([]);
  const [organisations, setOrganisations] = useState([]);


  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMerchantsDataLoading, setIsMerchantsDataLoading] = useState(false);
  const [fareMediaIssuersLoading, setFareMediaIssuersLoading] = useState(false);
  const [organisationsLoading, setOrganisationsLoading] = useState(false);
  const [fareMediaTypesLoading, setFareMediaTypesLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem('authData');

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }
  const fetchFareMedia = async () => {
    setIsFormLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media`;
      const response = await axios.post(
        url,
        { fare_media_id: id },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );
      const fare_media = response.data.data;
      setuid(fare_media.uid);
      setserial_num(fare_media.serial_num);

      setfare_media_type_id(fare_media.fare_media_type_id);
      setinit_balance(fare_media.init_balance);
      setfare_media_issuer_id(fare_media.fm_issuer_id);
      setorganisation_id(fare_media.org_id);
      setselling_price(fare_media.selling_price);
      setblacklist_note(fare_media.blacklisted_note);
      setis_blacklist(fare_media.is_blacklisted);
    } catch (error) {
      setError(`Failed to fetch fare_media [${error}]`);
      toast.error(`Failed to fetch fare_media [${error}]`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setIsFormLoading(false);
    }
  };
  const fetchFareMediaIssuers = async () => {
    setFareMediaIssuersLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media_issuers`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setFareMediaIssuers(response.data.data);
    } catch (error) {
      toast.error(`Failed to get fare media issuers [${error}]`);
    } finally {
      setFareMediaIssuersLoading(false);
    }
  };
  const fetchOrganisations = async () => {
    setOrganisationsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_organisations`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setOrganisations(response.data.data);
    } catch (error) {
      toast.error(`Failed to get organisations [${error}]`);
    } finally {
      setOrganisationsLoading(false);
    }
  };
  const fetchFareMediaTypes = async () => {
    setFareMediaTypesLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media_types`, {
        headers: {
          'access-token': userAuthData?.access_token,
          uid: userAuthData?.uid,
          client: userAuthData?.client,
        },
      });
      setFareMediaTypes(response.data.data);
    } catch (error) {
      toast.error(`Failed to get fare media types [${error}]`);
    } finally {
      setFareMediaTypesLoading(false);
    }
  };
  useEffect(() => {
    fetchFareMedia();
    fetchFareMediaIssuers();
    fetchOrganisations();
    fetchFareMediaTypes();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/update_fare_media`;
      const response = await axios.post(
        url,
        {
          fare_media_wanted_updated_id: id,
          fare_media_type_id: fare_media_type_id,
          init_balance: parseFloat(init_balance),
          fare_media_issuer_id: fare_media_issuer_id,
          organisation_id: organisation_id,
          selling_price: parseFloat(selling_price),
          blacklist_note: blacklist_note ? blacklist_note.trim() : blacklist_note,
          is_blacklist: is_blacklist,
        },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );

      toast.success('fare Media updated successfully!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        toastStyle: {
          backgroundColor: '#F1662D',
          color: 'white',
        },
      });
      history.push( came_from && came_from == "one" 
        ? `/CardsManagement/fareMedia/${id}`:
        `/CardsManagement/fareMedia/`
      )
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
      
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
       
        toast.error(`${error}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box sx={{ marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733', fontWeight: 'bold', marginBottom: '16px' }}>
          {intl.formatMessage({ id: 'EditFareMedia' })}
        </Typography>

        {isFormLoading ? (
          <CircularProgress style={{ display: "block" }} />
        ) : (
error != null ? <h4 style={{textAlign:"center"}}>{intl.formatMessage({ id: 'somethingWrong' })}</h4>:
          <Box component="form" onSubmit={handleSubmit}>
            <div className='parent-form'>
              <div>
                <label className='labelStyle'>{intl.formatMessage({ id: 'uid' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="uid"
                  value={uid}
                  disabled
                />

                <label className='labelStyle'>{intl.formatMessage({ id: 'serial_num' })}</label>
                <input
                  type="text"
                  className='inputStyle'
                  id="serialNumber"
                  value={serial_num}
                  disabled
                />
                <label className='labelStyle'>{intl.formatMessage({ id: 'init_balance' })}</label>
                <input
                  type="number"
                  min="0"
                  step="any"
                  className='inputStyle'
                  id="init_balance"
                  value={init_balance}
                  onChange={(e) => setinit_balance(e.target.value)}
                  required
                />
                <label className='labelStyle'>{intl.formatMessage({ id: 'selling_price' })}</label>
                <input
                  type="number"
                  min="0"
                  step="any"
                  className='inputStyle'
                  id="selling_price"
                  value={selling_price}
                  onChange={(e) => setselling_price(e.target.value)}
                  required
                />

                    <label className='labelStyle'
              >{intl.formatMessage({ id: 'is_blacklisted' })}</label>
              <input
               style={{margin:"10px -5px",padding:"10px",width:"40px"}}
                type="checkbox"
                checked={is_blacklist}
                onChange={(e) => setis_blacklist(e.target.checked)}
              />   <br></br>
             { is_blacklist && <>
                <label className='labelStyle'>{intl.formatMessage({ id: 'Blacklisted_note' })} </label>
                <textarea  name="blacklist_note" rows="4" cols="50"
                onChange={(e) => setblacklist_note(e.target.value)}
                  className='inputStyle'
                >
                  {blacklist_note}
               </textarea>
               </>}

              </div>
              <div>     <label className='labelStyle'>{intl.formatMessage({ id: 'type' })}</label>
                {fareMediaTypesLoading ? (
                  <CircularProgress size={24} sx={{ display: "block" }} />
                ) : (
                  <select
                    className='inputStyle'
                    id="fareMediaType"
                    value={fare_media_type_id}
                    onChange={(e) => setfare_media_type_id(e.target.value)}
                    required
                  >
                    {fareMediaTypes.map((fareMediaType) => (
                      <option key={fareMediaType.id} value={fareMediaType.id}>
                        {currentLanguageCode === "en-us" ?
                          fareMediaType.en_name :
                          fareMediaType.ar_name}
                      </option>
                    ))}
                  </select>
                )}
                <label className='labelStyle'>{intl.formatMessage({ id: 'issuer_name' })}</label>
                {fareMediaIssuersLoading ? (
                  <CircularProgress size={24} sx={{ display: "block" }} />
                ) : (
                  <select
                    className='inputStyle'
                    id="fareMediaIssuers"
                    value={fare_media_issuer_id}
                    onChange={(e) => setfare_media_issuer_id(e.target.value)}
                    required
                  >
                    {
                      fareMediaIssuers.map((fareMediaIssuers) => (
                        <option key={fareMediaIssuers.id} value={fareMediaIssuers.id}>
                          {currentLanguageCode === 'en-us' ? fareMediaIssuers.en_issuer_name : fareMediaIssuers.ar_issuer_name}
                        </option>
                      ))
                    }
                  </select>
                )}
                <label className='labelStyle'>{intl.formatMessage({ id: 'organization' })}</label>
                {organisationsLoading ? (
                  <CircularProgress size={24} sx={{ display: "block" }} />
                ) : (
                  <select
                    className='inputStyle'
                    id="organisations"
                    value={organisation_id}
                    onChange={(e) => setorganisation_id(e.target.value)}
                    required
                  >
                    {
                      organisations.map((organisation) => (
                        <option key={organisation.id} value={organisation.id}>
                          {currentLanguageCode === 'en-us' ? organisation.en_name : organisation.ar_name}
                        </option>
                      ))
                    }
                  </select>
                )} </div>
            </div>
            <div sx={{ marginBottom: 2, display: "block" }}>
              <button
                type="submit"
                className='updateBtnStyle'
                disabled={isLoading}
              >
                {isLoading ? intl.formatMessage({ id: 'Updating' }) : intl.formatMessage({ id: 'update' })}
              </button>
              <button
                className='cancelBtnStyle'
                onClick={() => 
                  history.push( came_from && came_from == "one" 
                  ? `/CardsManagement/fareMedia/${id}`:
                  `/CardsManagement/fareMedia/`
                )}
              >
                {intl.formatMessage({ id: 'Back' })}
              </button>
            </div>
          </Box>

        )}
      </Box>
      <ToastContainer />
    </>
  );
};

export default EditFareMedia;
