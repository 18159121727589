// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Suspense } from 'react'
//  import "../style.css";
// import Loading from "../images/loading2.svg";

import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { TextCell } from 'app/components/shared/table';
import { useIntl } from 'react-intl';


const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);
const TicketsTranscTable = ({ transactions, isLoading, convertToLocalTime, tabsValue }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const intl = useIntl();
  const classes = useStyles();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  return (
    <Table
      className={classes.table}
      size="small"
      aria-label="transcactions Table"

    >
      <TableHead style={{ border: "0" }} >
        <TableRow style={{ border: "0" }} >
          <TableCell align="center">
            {intl.formatMessage({ id: 'transID' })}
          </TableCell>
          <TableCell align="center">
            {intl.formatMessage({ id: 'ticketPrice' })}
          </TableCell>
          <TableCell align="center">
            {intl.formatMessage({ id: 'ticketDate' })}
          </TableCell>
          <TableCell align="center">
            {intl.formatMessage({ id: 'driverName' })}
          </TableCell>

          <TableCell align="center">
            {intl.formatMessage({ id: 'driverUserName' })}
          </TableCell>

          <TableCell align="center">
            {intl.formatMessage({ id: 'axisName' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          isLoading ? (
            <tr> <td colSpan={6} style={{ textAlign: "center", padding: "15px" }}>
                  {intl.formatMessage({ id: 'loading' })}
            </td></tr>
          ) :
            transactions?.length > 0 ?
              transactions?.map((r, i) =>
                <TableRow key={r.id} className={classes.row}>
                  <TextCell
                    label={r.ticket_serial}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                  <TextCell
                    label={r.price}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                  <TextCell
                    label={convertToLocalTime(r.obc_time)}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                  <TextCell
                    label={r.driver_name}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                  <TextCell
                    label={r.driver_username}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                  <TextCell
                    label={currentLanguageCode == "ar-eg" ? r.axis_ar_name : r.axis_en_name}
                    align="center"
                    style={{ fontFamily: 'Cairo', fontWeight: 600 }}
                  />
                </TableRow>
              )
              : <tr> <td colSpan={6} style={{ textAlign: "center", padding: "15px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</td></tr>
        }
      </TableBody>
    </Table>
  )

};

export { TicketsTranscTable };
