// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
import { useTypedSelector } from 'helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@material-ui/core';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { TableControlDataView } from './view-fareMedia-transactions/aboveTableFunctions/tableControlDataView';
import { TableAnalysis } from './view-fareMedia-transactions/aboveTableFunctions/tableAnalysis';
import { TransactionTabs } from './view-fareMedia-transactions/transactionTabs';
import { TableFilter } from './view-fareMedia-transactions/aboveTableFunctions/tableFilter';
import { TicketsTranscTable } from './view-fareMedia-transactions/ticketsTranscTable';
import { ChargingTranscTable } from './view-fareMedia-transactions/chargingTranscTable';

import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import PaidIcon from '@mui/icons-material/Paid';
import "react-datepicker/dist/react-datepicker.css";

const ViewFareMedia = () => {

  const { id } = useParams();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us';
  const intl = useIntl();
  const history = useHistory();


  const [fareMediaData, setFareMediaData] = useState(null);


  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [error, setError] = useState(null);
  //displaying transactions
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [isFilterClicked, setIsFilterClicked] = useState<any>(0);
  //res
  const [transactions, setTransactions] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<any>(0);
  const [transactionsTotalAmount, setTransactionsTotalAmount] = useState(null);
  const [transactionsNumber, setTransactionsNumber] = useState(null);
  //datetime handle 
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterStartDateValue, setFilterStartDateValue] = useState(null);
  const [filterEndDateValue, setFilterEndDateValue] = useState(null);
  // const [fareMediaInfo, setFareMediaInfo] = useState(null);
  const [transactionsBaseUrl, setTransactionsBaseUrl] = useState(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_pay_ticket_transactions_for_one_fare_media`);
  const [tabsValue, setTabsValue] = React.useState(0);
  //
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem('authData');

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    // 0 -> tickets
    // 1 -> charging
    if (newValue == 0) {
      setTransactionsBaseUrl(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_pay_ticket_transactions_for_one_fare_media`)
    } else {
      setTransactionsBaseUrl(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_charging_transactions_for_one_fare_media`)
    }
    setTabsValue(newValue);

    setPage(1);
    setIsFilterClicked(0);
    setTransactions([])
    setPageCount(0)
    setIsLoading(false);
    setLimit(10)
    setTransactionsNumber(null)
    setTransactionsTotalAmount(null)
    setFilterStartDateValue(null)
    setFilterEndDateValue(null)
  };

  function handlePrevious() {

    setPage((p) => {
      if (p === 1) return p;
      return p - 1;
    });
  }
  function handleNext() {

    setPage((p) => {
      if (p === pageCount) return p;
      return p + 1;
    });
  }
  function handleChangeLimit(lim) {

    setLimit(lim);
    setPage(1)
  }
  function convertToFormattedUTC(utcDateString) {
    const date = new Date(utcDateString);
    const options = { timeZone: 'UTC' };
    return date.toLocaleString('en-US', options); // Formats the UTC date
  }

  const runDateTimeFilter = (e) => {
    e.preventDefault();
    const formattedStartDate = format(startDate, 'MM/dd/yyyy, hh:mm:ss a');
    const formattedEndDate = format(endDate, 'MM/dd/yyyy, hh:mm:ss a');

    setFilterStartDateValue(formattedStartDate)
    setFilterEndDateValue(formattedEndDate)
    setPage(1)
    setIsFilterClicked(1);
    handleClose();
  };
  const clearFilterResultOnly = () => {
    setPage(1);
    setIsFilterClicked(0);
    setFilterStartDateValue(null)
    setFilterEndDateValue(null)
    setStartDate(new Date())
    setEndDate(new Date())
  };
  //handle filteration part
  const [anchorEl, setAnchorEl] = React.useState(null);
  const filterPopoverClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const idOfPopUp = open ? 'simple-popover' : undefined;
  const HandleMessage = (msg, type) => {
    if (type === "warn")
      toast.warn(msg)
    else if (type === "success")
      toast.success(msg)
    else if (type === "error")
      toast.error(msg)
  };



  const fetchTransactions = (serial_num) => {
    let url = (`${transactionsBaseUrl}?page=${page}&pageSize=${limit}`)
    setIsLoading(true)
    // alert(fareMediaData.serial_num);

    axios.post(url,
      {
        fare_media_serial: serial_num,
        startDate: filterStartDateValue,
        endDate: filterEndDateValue
      },
      {
        headers:
        {
          'access-token': userAuthData['access-token'],
          'uid': userAuthData.uid,
          'client': userAuthData.client
        }
      }
    )
      .then((res) => {
        setTransactions(res.data.data.result)
        setPageCount(res.data.data.totalPages)
        setIsLoading(false);

        setTransactionsNumber(res.data.data.totalCount)
        setTransactionsTotalAmount(res.data.data.totalAmount)
      })
      .catch((error) => {


        if (error && error.response && error.response.data && error.response.data.message) {
          HandleMessage(` ${error.response.data.message}`, "error")
        } else {
          HandleMessage(`${error}`, "error")
        }
        setTransactions([])
        setTransactionsNumber(null)
        setTransactionsTotalAmount(null)
        setIsLoading(false);

        /* setFilterStartDateValue(null)
         setFilterEndDateValue(null)*/
      })
  }


  const fetchFareMedia = async () => {
    setIsFormLoading(true);
    setError(null);
    try {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/get_fare_media`;
      const response = await axios.post(
        url,
        { fare_media_id: id },
        {
          headers: {
            'access-token': userAuthData?.access_token,
            uid: userAuthData?.uid,
            client: userAuthData?.client,
          },
        }
      );

      setFareMediaData(response.data.data)
      fetchTransactions(response.data.data.serial_num);


    } catch (error) {
      setError(`Failed to fetch fare_media [${error}]`);
      toast.error(`Failed to fetch fare_media [${error}]`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
      });
    } finally {
      setIsFormLoading(false);
    }
  };


  useEffect(() => {
    fetchFareMedia();
  }, []);
  useEffect(() => {
    fareMediaData && fareMediaData.serial_num && fetchTransactions(fareMediaData.serial_num);
  }, [page, limit, filterStartDateValue, filterEndDateValue, transactionsBaseUrl])

  const hasWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'FareMedia')
  );
  function convertToLocalTime(utcDateString) {
    const date = new Date(utcDateString);
    console.log(date);

    return date.toLocaleString(); // Converts to local time and formats it
  }


  return (
    <>
      <Box sx={{ marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733', fontWeight: "bold", marginBottom: "16px" }}>
          {intl.formatMessage({ id: 'FareMediaInfo' })}
        </Typography>

        {isFormLoading ? (
          <CircularProgress style={{ display: "block" }} />
        ) : (
          error != null ? <h4 style={{ textAlign: "center" }}>{intl.formatMessage({ id: 'somethingWrong' })}</h4> :
            <>
              <div className='table-view-item-info' style={{ width: "90%" }}>
                <div className="name-and-actions-btns-sec">
                  <h5 className="title-item-sec">
                    {fareMediaData.serial_num}
                  </h5>
                  <div className="actions-btns-sec">
                    {hasWriteAccess &&
                      <> <button className="styleAddBtn" style={{ position: "inherit", marginTop: "-10px", paddingBottom: "7px", paddingTop: "8px" }} onClick={() => history.push(`/CardsManagement/fareMedia/${id}/edit?came_from=one`)}>
                        <EditIcon style={{ fontSize: "24px", paddingRight: "3px", paddingLeft: "3px" }} /> {intl.formatMessage({ id: 'edit' })}
                      </button>
                      </>}

                  </div>
                </div>
                <div className="data-view-sec" style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div style={{ width: "50%" }}>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'uid' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.uid}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'serial_num' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.serial_num}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'parent_wallet_balance' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.wallet_balance} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>


                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'init_balance' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.init_balance} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'fare_media_type_name' })}
                      </div>
                      <div className="value-of-one-info">

                        {currentLanguageCode == "en-us" ? fareMediaData.en_fare_media_type_name : fareMediaData.ar_fare_media_type_name}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'issuer_name' })}
                      </div>
                      <div className="value-of-one-info">

                        {currentLanguageCode == "en-us" ? fareMediaData.en_issuer_name : fareMediaData.ar_issuer_name}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'org_name' })}
                      </div>
                      <div className="value-of-one-info">

                        {currentLanguageCode == "en-us" ? fareMediaData.en_org_name : fareMediaData.ar_org_name}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'selling_price' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.selling_price ? parseFloat(fareMediaData.selling_price) : fareMediaData.selling_price} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>

                    <div className="one-info">
                      <div className="title-of-one-info" >
                        {intl.formatMessage({ id: 'is_sold' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.is_sold ? <> <CheckCircleIcon style={{ fontSize: "15px", marginTop: "-3px" }} /> {intl.formatMessage({ id: 'yes' })}</> : <> <DisabledByDefaultIcon style={{ fontSize: "15px", marginTop: "-3px" }} /> {intl.formatMessage({ id: 'no' })}</>}
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'is_blacklisted' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.is_blacklisted ? <> <CheckCircleIcon style={{ fontSize: "15px", marginTop: "-3px" }} /> {intl.formatMessage({ id: 'yes' })}</> : <> <DisabledByDefaultIcon style={{ fontSize: "15px", marginTop: "-3px" }} /> {intl.formatMessage({ id: 'no' })}</>}

                      </div>
                    </div>
                    {fareMediaData.is_blacklisted && <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'Blacklisted_note' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.blacklisted_note}
                      </div>
                    </div>}

                    {fareMediaData.fm_user_id && <>  <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'user_name' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.user_name}
                      </div>
                    </div>
                      <div className="one-info">
                        <div className="title-of-one-info">
                          {intl.formatMessage({ id: 'user_email' })}
                        </div>
                        <div className="value-of-one-info">
                          {fareMediaData.user_email}
                        </div>
                      </div>
                      <div className="one-info">
                        <div className="title-of-one-info">
                          {intl.formatMessage({ id: 'user_phone' })}
                        </div>
                        <div className="value-of-one-info">
                          {fareMediaData.user_phone}
                        </div>
                      </div></>}
                  </div>

                  <div style={{ width: "50%" }}>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'created_at' })}
                      </div>
                      <div className="value-of-one-info">
                        {convertToLocalTime(fareMediaData.created_at)} <EventNoteIcon style={{ fontSize: "15px", marginTop: "-3px" }} />
                      </div>
                    </div>

                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'updated_at' })}
                      </div>
                      <div className="value-of-one-info">
                        {convertToLocalTime(fareMediaData.updated_at)} <EventNoteIcon style={{ fontSize: "15px", marginTop: "-3px" }} />
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'expiration_date' })}
                      </div>
                      <div className="value-of-one-info">
                        {convertToLocalTime(fareMediaData.expiration_date)} <EventNoteIcon style={{ fontSize: "15px", marginTop: "-3px" }} />
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'issuing_date' })}
                      </div>
                      <div className="value-of-one-info">
                        {convertToLocalTime(fareMediaData.issuing_date)} <EventNoteIcon style={{ fontSize: "15px", marginTop: "-3px" }} />
                      </div>
                    </div>
                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'distribution_date' })}
                      </div>
                      <div className="value-of-one-info">
                        {convertToLocalTime(fareMediaData.distribution_date)} <EventNoteIcon style={{ fontSize: "15px", marginTop: "-3px" }} />
                      </div>
                    </div>




                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'total_charging_amount' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.total_charging_amount} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>

                    <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'total_ticketing_amount' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.total_ticketing_amount} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>
                    {userPersonalData.is_super_admin && <> <div className="one-info">
                      <div className="title-of-one-info">
                        {intl.formatMessage({ id: 'calculated_balance' })}
                      </div>
                      <div className="value-of-one-info">
                        {fareMediaData.calculated_balance} {intl.formatMessage({ id: 'currency' })}
                      </div>
                    </div>
                      <div className="one-info">
                        <div className="title-of-one-info">
                          {intl.formatMessage({ id: 'BalanceNotes' })}
                        </div>
                        <div className="value-of-one-info">
                          {
                            fareMediaData.balance_diff > 0 ?
                              <span style={{ color: "black", fontWeight: "bold" }}>{intl.formatMessage({ id: 'addToFareMediaBalance' })} {fareMediaData.balance_diff} {intl.formatMessage({ id: 'currency' })} </span> :
                              fareMediaData.balance_diff < 0 ?
                                <span style={{ color: "red", fontWeight: "bold" }}>{intl.formatMessage({ id: 'removeFareMediaBalance' })} {fareMediaData.balance_diff}  {intl.formatMessage({ id: 'currency' })} </span> :
                                <span style={{ color: "green", fontWeight: "bold" }}>{intl.formatMessage({ id: 'BalanceIsCorrect' })} </span>
                          }
                        </div>
                      </div></>}
                  </div>

                </div>
                <div className="transactions-sec">
                  {fareMediaData && fareMediaData.id ? (
                    <>
                      <TransactionTabs tabsValue={tabsValue} handleChangeTabs={handleChangeTabs} />
                      <div className='table-features-section'>

                        {transactions?.length > 0 ? <TableAnalysis transactionsNumber={transactionsNumber} transactionsTotalAmount={transactionsTotalAmount} /> : <></>}

                        {transactions?.length > 0 ? <TableControlDataView
                          page={page} pageCount={pageCount}
                          limit={limit} handlePrevious={handlePrevious}
                          handleNext={handleNext} handleChangeLimit={handleChangeLimit} /> : <></>}

                        <TableFilter isFilterClicked={isFilterClicked} filterStartDateValue={filterStartDateValue}
                          clearFilterResultOnly={clearFilterResultOnly} transactions={transactions}
                          filterPopoverClickButton={filterPopoverClickButton} idOfPopUp={idOfPopUp}
                          open={open} anchorEl={anchorEl}
                          handleClose={handleClose} runDateTimeFilter={runDateTimeFilter}
                          startDate={startDate} setStartDate={setStartDate}
                          endDate={endDate} setEndDate={setEndDate} />

                      </div>
                      <div className="parentTable-customer-support" >
                        {tabsValue == 0 ?
                          <TicketsTranscTable transactions={transactions} isLoading={isLoading} convertToLocalTime={convertToLocalTime} tabsValue={tabsValue} />
                          : <ChargingTranscTable transactions={transactions} isLoading={isLoading} convertToFormattedUTC={convertToFormattedUTC} tabsValue={tabsValue} />
                        }
                      </div>
                    </>) : <></>}
                </div>
              </div>

            </>
        )}
        <button
          className='cancelBtnStyle'
          style={{ marginTop: "20px" }}
          onClick={() => history.push(`/CardsManagement/fareMedia/`)}
        >
          {intl.formatMessage({ id: 'Back' })}
        </button>
      </Box>
      <ToastContainer /></>

  );
};

export default ViewFareMedia;
