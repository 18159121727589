// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { FluxStandardAction } from 'redux-promise-middleware';

import { alertConstants } from '_constants';
import { Alert } from 'types';
import { useIntl } from 'react-intl';
const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
const alert = (state = null, action: FluxStandardAction): Alert | null => {
  
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.payload,
      };

    case alertConstants.ERROR:
      
      return {
        type: 'error',
        message: 
        ( action.payload &&  (action.payload).includes("Device limit exceeded for") == true)
        ?  currentLanguageCode =='en-us' ? "Sorry, you have reached your limit" :"عذرا , لقد وصلت إلى الحد الاقصي" 
        : action.payload,
      };

    case alertConstants.CLEAR:
      return null;

    default:
      return state;
  }
};

export { alert };
