// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { Organizations } from 'app/configuration/organizations';

import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasReadAccessToMasterConfigSubSystem } from 'selectors';

const MasterConfig: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const intl = useIntl();

  const canViewOrganizations = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'Organizations')
  );
  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'configuration' })}
        headerIcon={SystemIcon.Configurations}
      />

      <Switch>
        <Redirect from={baseURL} exact to={`${baseURL}/organizations`} />

        <Route path={`${baseURL}/organizations`}>
          {canViewOrganizations ? <Organizations /> : <NotAuthorized />}
        </Route>
        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { MasterConfig };
