// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import "../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';
import { Card, Col, Container, Row } from 'react-bootstrap';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import GppBadOutlinedIcon from '@mui/icons-material/GppBadOutlined';
const FaraMediaInfoContainer = ({fareMediaInfo,convertToLocalTime}) => {
      const dispatch = useDispatch();
      const match = useRouteMatch();
      const intl = useIntl();
      const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
      // Function to check the expiration status
      const checkExpirationStatus = (expirationDate) => {
        const today = new Date();
        const expiryDate = new Date(expirationDate);

        if (today > expiryDate) {
            return <GppBadOutlinedIcon style={{color:"red", fontSize: "20px",margin:"-3px 5px 1px 5px"}} />;
        } else if (today.toDateString() === expiryDate.toDateString()) {
     
            return <GppMaybeOutlinedIcon style={{backgroundColor:"gold",margin:"-3px 5px 1px 5px",padding:"0",borderRadius:"20px 20px 40px 40px ",  fontSize: "20px"}} />;
        } else {
            return <EventNoteIcon style={{ fontSize: "15px",margin:"-3px 5px 1px 5px"}} />;
        }
    };
return(
<div className='parent-fare-media-info'>

<Container className='fare-media-info-container'>
  <Row>
      <Col >
          <Card className='fare-media-info-card'>
              <Card.Body>
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'serial_num' })} :</strong> {fareMediaInfo.serial_num}
                      </Card.Text>
                      {/*<Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'uid' })} :</strong> {fareMediaInfo.uid}
                      </Card.Text>*/}
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'parent_wallet_balance' })} :</strong> {fareMediaInfo.parent_wallet_balance} <PaidIcon style={{fontSize:"15px",marginTop:"-3px"}}/>
                      </Card.Text>
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'fare_media_type_name' })} :</strong> {currentLanguageCode == "en-us"? fareMediaInfo.en_fare_media_type_name : fareMediaInfo.ar_fare_media_type_name}
                      </Card.Text>
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'is_blacklisted' })} :</strong> { fareMediaInfo.is_blacklisted ?<>{intl.formatMessage({ id: 'yes' })} <CheckCircleIcon style={{fontSize:"15px",marginTop:"-3px"}}/></>  : <>{intl.formatMessage({ id: 'no' })} <DisabledByDefaultIcon style={{fontSize:"15px",marginTop:"-3px"}}/> </>}
                      </Card.Text>
                    
                
              </Card.Body>
          </Card>
      </Col>
      <Col >
          <Card className='fare-media-info-card'>
              <Card.Body>
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'user_name' })} :</strong> {fareMediaInfo.user_name}
                      </Card.Text>
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'user_phone' })} :</strong> {fareMediaInfo.user_phone} 
                      </Card.Text> 
                      <Card.Text className='fare-media-info-card-text'>
                          <strong>{intl.formatMessage({ id: 'user_email' })} :</strong> {fareMediaInfo.user_email} 
                      </Card.Text>
                      <Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'init_balance' })} :</strong> {fareMediaInfo.init_balance} <PaidIcon style={{fontSize:"15px",marginTop:"-3px"}}/>
                    </Card.Text>
              </Card.Body>
          </Card>
      </Col>
      <Col>
          <Card className='fare-media-info-card'>
              <Card.Body>
                    <Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'issuer_name' })} :</strong> {currentLanguageCode == "en-us"? fareMediaInfo.en_issuer_name : fareMediaInfo.ar_issuer_name}
                    </Card.Text>
                    <Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'org_name' })} :</strong> {currentLanguageCode == "en-us"? fareMediaInfo.en_org_name : fareMediaInfo.ar_org_name}
                    </Card.Text>
            
   
                    {/*<Card.Text >
                                <strong>{intl.formatMessage({ id: 'account_id' })} :</strong> {fareMediaInfo.account_id}
                    </Card.Text>*/}
                    <Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'created_at' })} :</strong> {convertToLocalTime(fareMediaInfo.created_at)} <EventNoteIcon style={{fontSize:"15px",marginTop:"-3px"}}/>
    {/*<span style={{marginLeft:"17px",marginRight:"17px"}}></span>*/}
                    </Card.Text>
                    {/*<Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'updated_at' })} :</strong> {convertToLocalTime(fareMediaInfo.updated_at)} <EditCalendarIcon style={{fontSize:"15px",marginTop:"-3px"}}/> 
                    </Card.Text>*/}
                    <Card.Text className='fare-media-info-card-text'>
                                <strong>{intl.formatMessage({ id: 'expiration_date' })} :</strong> {convertToLocalTime(fareMediaInfo.expiration_date)} 
                                {checkExpirationStatus(fareMediaInfo.expiration_date)}      
                    </Card.Text>
              </Card.Body>
          </Card>
      </Col>

  </Row>
</Container>



</div>
   )
  
};

export { FaraMediaInfoContainer };
