import React from 'react';

import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { useTypedSelector } from 'helpers';
import {
  getAdminListOfPermissionsById,
  getLoggedAdminMasterConfigPermissions,
} from 'selectors';

import { SystemPermissions } from '../system-permissions';

import masterConfigIcon from 'assets/master-configuration.svg';

const AdminMasterPermissions: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const adminId = match.params.id;
  const intl = useIntl();

  const currentAdminPermissions = useTypedSelector(state =>
    getAdminListOfPermissionsById(state, adminId)
  );

  const currentAdminMasterConfigPermissions =
    currentAdminPermissions?.master_configuration;

  const loggedAdminMasterConfigPermissions = useTypedSelector(state =>
    getLoggedAdminMasterConfigPermissions(state)
  );

  const loggedAdminMasterConfigSubSystems = Object.keys(
    loggedAdminMasterConfigPermissions
  );
  
  return (
    <>
      {!!loggedAdminMasterConfigSubSystems?.length && (
        <SystemPermissions
          {...{
            accordionSummaryProps: {
              title: intl.formatMessage({ id: 'masterConfiguration' }),
              ariaControls: 'master-config-content',
              icon: masterConfigIcon,
            },
            systemName: 'master_configuration',
            subSystems: loggedAdminMasterConfigSubSystems || [],
            permissions: currentAdminMasterConfigPermissions || {},
          }}
        />
      )}
    </>
  );
};

export { AdminMasterPermissions };
