// @ts-nocheck
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line radix
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FilterChargingTransaction } from './filter-charging-transaction';
import { useIntl } from "react-intl";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { basicButtonStyles } from "app/shared/styles";

const useStyles = makeStyles(() =>
  createStyles({
    table: { 
      minWidth: 750, 
      overflow: 'scroll', 
      whiteSpace: 'nowrap' 
    },
    addButton: { 
      ...basicButtonStyles 
    },
    row: { 
      '&:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9', // Light gray background for odd rows
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#ffffff', // White background for even rows
      },
      cursor: 'default', // Default cursor for table rows
    },
  })
);

const ChargingTransactionsTable: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(true);
  const [mainApiResponseData, setMainApiResponseData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [dataCountNumber, setDataCountNumber] = useState<number | null>(null);
  const [sortBy, setSortBy] = useState<string>('TRANSACTION_MTIME');
  const [sortOrder, setSortOrder] = useState<string>('DESC');
  const [searchParams, setSearchParams] = useState<Record<string, string>>({});
  const [merchantRef, setMerchantRef] = useState<string>('');
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);

  const authData = localStorage.getItem("authData");
  let loggedInUserAccessTokenClientUid: any = '';
  const history = useHistory();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us';

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    loggedInUserAccessTokenClientUid = parsedAuthData.userToken;
  }

  useEffect(() => {
    const makeRequest = () => {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/getAllChargingTransactions`;

      setIsLoading(true);

      axios.post(url, searchParams, {
        headers: {
          'Content-Type': 'application/json',
          'access-token': loggedInUserAccessTokenClientUid['access-token'],
          'uid': loggedInUserAccessTokenClientUid.uid,
          'client': loggedInUserAccessTokenClientUid.client
        },
        params: {
          pageSize: limit,
          page,
          sortBy,
          sortOrder,
        }
      })
        .then((res) => {
          setMainApiResponseData(res.data.data.result);
          setDataCountNumber(res.data.data.totalCount);
          setPageCount(res.data.data.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("API ERROR", err);
          toast.error(`Sorry, ${err.message}. Please try again later.`);
          setIsLoading(false);
        });
    };

    makeRequest();
  }, [page, limit, sortBy, sortOrder, searchParams]);

  const handlePrevious = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNext = () => {
    if (page < pageCount) setPage(page + 1);
  };

  const handleChangeLimit = (lim: number) => {
    setLimit(lim);
    setPage(1);
  };

  const applyFilters = (filters: Record<string, string>) => {
    setSearchParams({ ...filters, merchantRef }); // Include merchantRef in the search params
    setPage(1);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearchParams(prevParams => ({ ...prevParams, merchantRef }));
    setPage(1);
    setIsSearchClicked(true);
  };

  /*const handleClearSearch = () => {
    setMerchantRef('');
    setSearchParams({});
    setPage(1);
    setIsSearchClicked(false);
  };*/
  const handleClearSearch = () => {
    setMerchantRef(''); // Reset the merchant reference input
    const updatedSearchParams = {...searchParams};
    delete updatedSearchParams.merchantRef; // Remove the merchantRef key from search params
    setSearchParams(updatedSearchParams);
    setIsSearchClicked(false);
  };
  const startItem = (page - 1) * limit + 1;
  const endItem = Math.min(page * limit, dataCountNumber || 0);

  const formatDateWithoutTimezone = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds} ${hours >= 12 ? 'PM' : 'AM'}`;
  };

  return (
    <div className='dev-contain-all-page-content'>
      <h2 className='header-of-subsystem-page'>
        <MonetizationOnIcon style={{ margin: "10px  10px 13px 10px", fontSize: "28px" }} />
        {intl.formatMessage({ id: 'ChargingTransactions' })}
      </h2>

      <div className='table-controller-sec'>
        <div style={{ display: "flex", marginTop: "0px", marginBottom: "0px" }}>
          <form onSubmit={handleSearchClick} className="input-search">
            <input
              type="text"
              style={{ width: "280px" }}
              value={merchantRef}
              onChange={(e) => setMerchantRef((e.target.value).trim())}
              placeholder={intl.formatMessage({ id: 'SearchByTransactionNumber' })}
            />
            <button
              style={currentLanguageCode === "en-us" ? { right: "8px" } : { left: "8px" }}
              type="submit"
            >
              <SearchIcon style={{ fontSize: "22px" }} />
            </button>
          </form>

          {isSearchClicked && (
            <button
              className="cancelBtnStyle"
              style={{ marginTop: "0px", marginBottom: "15px" }}
              onClick={handleClearSearch}
            >
              <ClearIcon style={{ fontSize: "22px", padding: "0" }} />
            </button>
          )}

          <FilterChargingTransaction
            applyFilters={applyFilters}
          />
        </div>
        <div style={{ direction: "ltr" }}>
          {mainApiResponseData?.length > 0 && <>
            <span style={{ margin: "10px", direction: "ltr" }}>
              {page}
              <span className='show-span'>
                <bdi style={{ margin: "10px" }}>
                  {intl.formatMessage({ id: 'From' })}
                </bdi>
              </span>
              {pageCount}
            </span>
            <button className='page-navigation-arrow' disabled={page === 1} onClick={handlePrevious}>
              <KeyboardArrowLeftIcon style={{ fontSize: "30px" }} />
            </button>
            <bdi>{startItem} - {endItem} {intl.formatMessage({ id: 'Of' })} {dataCountNumber}</bdi>
            <button className='page-navigation-arrow' disabled={page === pageCount} onClick={handleNext}>
              <KeyboardArrowRightIcon style={{ fontSize: "30px" }} />
            </button>

            <span style={{ margin: "10px" }}>
              <bdi>
                <span className='show-span' style={{ margin: "10px" }}>{intl.formatMessage({ id: 'Show' })}</span>
                <select className='show-select' onChange={(e) => handleChangeLimit(parseInt(e.target.value))}>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </bdi>
            </span></>}
        </div>
      </div>

      <div className="Table-Wrapper">
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: "7px" }}>
            <CircularProgress style={{ width: "42px" }} />
          </div>
        ) : (
          <Table className={classes.table} size="small" aria-label="charging transactions table">
            <TableHead style={{ border: "0" }}>
              <TableRow style={{ border: "0" }}>
                <TableCell align="center">{intl.formatMessage({ id: 'TransactionNumber' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'timeAndDate' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'deviceSN' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'uid' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'SN' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'amount' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'TotalFees' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'Vat' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'PaidAmount' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'NewBalance' })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mainApiResponseData?.length > 0 ?
                mainApiResponseData.map((r) => (
                  <TableRow className={classes.row} key={r.ct_id}>
                    <TableCell align="center">{r.merchant_ref}</TableCell>
                    <TableCell align="center">{formatDateWithoutTimezone(r.transaction_mtime)}</TableCell>
                    <TableCell align="center">{r.device_serial_number}</TableCell>
                    <TableCell align="center">{r.uid}</TableCell>
                    <TableCell align="center">{r.card_serial_number}</TableCell>
                    <TableCell align="center">{r.amount}</TableCell>
                    <TableCell align="center">{parseFloat(r.com_prov + r.com_ret + r.flat_fee)}</TableCell>
                    <TableCell align="center">{r.vat}</TableCell>
                    <TableCell align="center">{parseFloat(r.paid_amount)}</TableCell>
                    <TableCell align="center">{r.card_amount}</TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center", padding: "15px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

export default ChargingTransactionsTable;

