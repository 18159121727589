// @ts-nocheck
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line radix
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Suspense } from 'react'
import { Link, NavLink, useHistory } from "react-router-dom";
//import NavBar from '../components/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import "../style.css";
//import Sidebar from '../components/Sidebar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import SearchIcon from '@mui/icons-material/Search';
import cookies from 'js-cookie'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import Loading from "../images/loading2.svg";
import ClearIcon from '@mui/icons-material/Clear';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import {
  Button,
  CircularProgress,
  Popover,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,

} from '@material-ui/core';
import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import { components } from "react-select";
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useTypedSelector } from 'helpers';
import { useSelector } from 'react-redux';
import { log } from 'console';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { hasWriteAccessToMasterConfigSubSystem } from "selectors";
import StyleIcon from '@mui/icons-material/Style';
import { Modal } from "react-bootstrap";
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FilterFareMedia } from "./filter-fareMedia";

import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
const useStyles = makeStyles(() =>
  createStyles({
    table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
    addButton: { ...basicButtonStyles },
    row: { ...tableRowStyles },
  })
);
const FareMediaTable: React.FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true); // Loading state
  //const history.push = useNavigate();
  const { t } = useTranslation()
  const history = useHistory();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
  //req
  const [mainApiResponseData, setMainApiResponseData] = useState<any[]>([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [pageCount, setPageCount] = useState<any>(0);
  const [dataCountNumber, setDataCountNumber] = useState(null);
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [sortOrder, setSortOrder] = useState<string>('DESC');
  const [searchParams, setSearchParams] = useState<Record<string, string>>({});
  const [serial_num, setSerial_num] = useState<string>('');
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false);
  let loggedInUserPersonalData: any = '';
  let loggedInUserAccessTokenClientUid: any = '';

  const authData = localStorage.getItem("authData");

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    loggedInUserPersonalData = parsedAuthData.userData;
    loggedInUserAccessTokenClientUid = parsedAuthData.userToken;

  }


  useEffect(() => {
    const makeRequest = () => {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/fareMedia/get_all_fare_media`;

      setIsLoading(true);

      axios.post(url, searchParams, {
        headers: {
          'Content-Type': 'application/json',
          'access-token': loggedInUserAccessTokenClientUid['access-token'],
          'uid': loggedInUserAccessTokenClientUid.uid,
          'client': loggedInUserAccessTokenClientUid.client
        },
        params: {
          pageSize: limit,
          page,
          sortBy,
          sortOrder,
        }
      })
        .then((res) => {
          setMainApiResponseData(res.data.data.result);
          setDataCountNumber(res.data.data.totalCount);
          setPageCount(res.data.data.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("API ERROR", err);
          toast.error(`${err.message}`);
          setIsLoading(false);
        });
    };

    makeRequest();
  }, [page, limit, sortBy, sortOrder, searchParams]);

  const handlePrevious = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNext = () => {
    if (page < pageCount) setPage(page + 1);
  };

  const handleChangeLimit = (lim: number) => {
    setLimit(lim);
    setPage(1);
  };

  const applyFilters = (filters: Record<string, string>) => {
    setSearchParams({ ...filters, serial_num }); 
    setPage(1);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearchParams(prevParams => ({ ...prevParams, serial_num }));
    setPage(1);
    setIsSearchClicked(true);
  };

  const handleClearSearch = () => {
    setSerial_num(''); // Reset the merchant reference input
    const updatedSearchParams = {...searchParams};
    delete updatedSearchParams.serial_num; 
    setSearchParams(updatedSearchParams);
    setIsSearchClicked(false);
  };
  const startItem = (page - 1) * limit + 1;
  const endItem = Math.min(page * limit, dataCountNumber || 0);



//adding bulk fare media 
const [showModal, setShowModal] = useState(false);
const [isUploading, setIsUploading] = useState(false);
const toggleModal = () => setShowModal(!showModal);

// Warn user on page close while uploading
useEffect(() => {
  const handleBeforeUnload = (e) => {
    if (isUploading) {
      e.preventDefault();
      e.returnValue = ''; // Standard message for modern browsers
    }
  };

  if (isUploading) {
    window.addEventListener('beforeunload', handleBeforeUnload);
  } else {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, [isUploading]);

// File upload handler
const handleFileUpload = async (e) => {
  const file = e.target.files[0];
  if (file /* && 
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    file.type === 'text/csv'*/) {
   
    setIsUploading(true);
    try {
      const data = new FormData();
      data.append('fare_media_file', file);
      const response = await axios.post(`${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/faremedia/add_bulk_fare_media`, data, {
        headers: { 'Content-Type': 'multipart/form-data',
           'access-token': loggedInUserAccessTokenClientUid['access-token'], 'uid': loggedInUserAccessTokenClientUid.uid, 'client': loggedInUserAccessTokenClientUid.client 
         },
      });
        toast.success('File uploaded successfully!');
        setShowModal(false)  
    } catch (error) {
      if (error && error.response && error.response.data && error.response.data.message) {
        if (String(error.response.data.message).includes("with the same")) {
        toast.error(currentLanguageCode == 'en-us' ? `Fare Media Already Exist` : `  تم ايجاد وسيلة دفع التعريفة تم ادخالها من قبل`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            style:{ zIndex: "99999999" }
          });
        } else{
  toast.error(`${error.response.data.message}`, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
  });
}
      
      } else {

        toast.error(`${error}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    } finally {
      setIsUploading(false);
    }
  } else {
    toast.error('Please upload a valid Excel file.');
  }
};

// Template download handler
const handleTemplateDownload = () => {
  const link = document.createElement('a');
  link.href = `${process.env.PUBLIC_URL}/Template.xlsx`;
  link.download = 'Template.xlsx';
  link.click();
};

function convertToLocalTime(utcDateString) {
  const date = new Date(utcDateString);
  console.log(date);
  
  return date.toLocaleString(); // Converts to local time and formats it
}
const hasWriteAccess = useTypedSelector(state =>
  hasWriteAccessToMasterConfigSubSystem(state, 'FareMedia')
);
const moveToEditPage = (id, event) => {
  event.stopPropagation(); // Stop event propagation
  history.push(`/CardsManagement/fareMedia/${id}/edit?came_from=all`);
}
const tableRowClickNavigation = (id) => {
  history.push(`/CardsManagement/fareMedia/${id}`);
}
  return (
    <div className='dev-contain-all-page-content'>
      <h2 className='header-of-subsystem-page'>
        <StyleIcon style={{ margin: "10px  10px 13px 10px", fontSize: "28px" }} />
        {intl.formatMessage({ id: 'FareMedia' })}
      </h2>
      {hasWriteAccess && <button className="styleAddBtn" style={
        currentLanguageCode == "en-us" ?
          { right: "90px" } :
          { left: "90px" }
      }
        onClick={() => history.push(`/CardsManagement/fareMedia/new`)}
      >
        {intl.formatMessage({ id: 'AddFareMedia' })}
      </button>}
      {hasWriteAccess && (
        <button
          className="styleAddBulkBtn"
          style={currentLanguageCode === 'en-us' ? { right: '230px' } : { left: '185px' }}
          onClick={toggleModal}
        >
          <UploadFileIcon />
          {intl.formatMessage({ id: 'import' })}
        </button>
      )}

      <Modal show={showModal} onHide={toggleModal} style={{ zIndex: "9999969" }}>
      {isUploading ? (
                 <Modal.Body>
                    <div style={{ textAlign: "center",display:"flex",justifyContent:"center", margin: "2px" }}>
                      <CircularProgress style={{ width: "50px",textAlign: "center"}} />
                    </div>
                </Modal.Body>
                ) :<>
        <Modal.Body>

       


          <label htmlFor="file-upload" className=" upload-style-btn-label mt-3">
            {isUploading ? intl.formatMessage({ id: 'Uploading' }) : <>
            <FileUploadIcon/>  { intl.formatMessage({ id: 'UploadFile' })}</> }
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            disabled={isUploading}
          />
 <button  className="download-style-btn" onClick={handleTemplateDownload}>
          <DownloadIcon/>    {intl.formatMessage({ id: 'DownloadTemplate' })}
          </button>
         

          
        </Modal.Body>
        <Modal.Footer style={{ direction: currentLanguageCode === 'en-us' ? 'ltr' : 'rtl' }}>
          <button className="download-style-btn" onClick={toggleModal}>
            {intl.formatMessage({ id: 'Cancel' })}
          </button>
        </Modal.Footer></>}
      </Modal>


  
      <div className='table-controller-sec'>
        <div style={{ display: "flex", marginTop: "0px", marginBottom: "0px" }}>
          <form onSubmit={handleSearchClick} className="input-search">
            <input
              type="text"
              style={{ width: "240px" }}
              value={serial_num}
              onChange={(e) => setSerial_num((e.target.value).trim())}
              placeholder={intl.formatMessage({ id: 'SearchBySerial' })}
            />
            <button
              style={currentLanguageCode === "en-us" ? { right: "8px" } : { left: "8px" }}
              type="submit"
            >
              <SearchIcon style={{ fontSize: "22px" }} />
            </button>
          </form>

          {isSearchClicked && (
            <button
              className="cancelBtnStyle"
              style={{ marginTop: "0px", marginBottom: "15px" }}
              onClick={handleClearSearch}
            >
              <ClearIcon style={{ fontSize: "22px", padding: "0" }} />
            </button>
          )}

          <FilterFareMedia
            applyFilters={applyFilters}
          />
        </div>
        <div style={{ direction: "ltr" }}>
          {mainApiResponseData?.length > 0 && <>
            <span style={{ margin: "10px", direction: "ltr" }}>
              {page}
              <span className='show-span'>
                <bdi style={{ margin: "10px" }}>
                  {intl.formatMessage({ id: 'From' })}
                </bdi>
              </span>
              {pageCount}
            </span>
            <button className='page-navigation-arrow' disabled={page === 1} onClick={handlePrevious}>
              <KeyboardArrowLeftIcon style={{ fontSize: "30px" }} />
            </button>
            <bdi>{startItem} - {endItem} {intl.formatMessage({ id: 'Of' })} {dataCountNumber}</bdi>
            <button className='page-navigation-arrow' disabled={page === pageCount} onClick={handleNext}>
              <KeyboardArrowRightIcon style={{ fontSize: "30px" }} />
            </button>

            <span style={{ margin: "10px" }}>
              <bdi>
                <span className='show-span' style={{ margin: "10px" }}>{intl.formatMessage({ id: 'Show' })}</span>
                <select className='show-select' onChange={(e) => handleChangeLimit(parseInt(e.target.value))}>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </bdi>
            </span></>}
        </div>
      </div>

      <div className="Table-Wrapper">
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: "7px" }}>
            <CircularProgress style={{ width: "42px" }} />
          </div>
        ) : (
          <Table className={classes.table} size="small" aria-label="charging transactions table">
            <TableHead style={{ border: "0" }}>
              <TableRow style={{ border: "0" }}>
                <TableCell align="center">{intl.formatMessage({ id: 'uid' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'SN' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'parent_wallet_balance' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'is_blacklisted' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'init_balance' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'fare_media_type_name' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'issuer_name' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'org_name' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'selling_price' })}</TableCell>
                {/*<TableCell align="center">{intl.formatMessage({ id: 'user_phone' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'user_email' })}</TableCell>*/}
                <TableCell align="center">{intl.formatMessage({ id: 'created_at' })}</TableCell>
                <TableCell align="center">{intl.formatMessage({ id: 'expiration_date' })}</TableCell>
                {hasWriteAccess && <><TableCell align="center">
                  {intl.formatMessage({ id: 'actions' })}
                </TableCell></>}
              </TableRow>
            </TableHead>
            <TableBody>
              {mainApiResponseData?.length > 0 ?
                mainApiResponseData.map((r) => (
                  <TableRow className={classes.row} key={r.id} onClick={() => tableRowClickNavigation(r.id)} >
                    <TableCell align="center">{r.uid}</TableCell>
                    <TableCell align="center">{r.serial_num}</TableCell>
                    <TableCell align="center">{r.parent_wallet_balance}</TableCell>
                    <TableCell align="center">{ r.is_blacklisted ?<> <CheckCircleIcon style={{fontSize:"15px",marginTop:"-3px"}}/> {intl.formatMessage({ id: 'yes' })}</>  : <> <DisabledByDefaultIcon style={{fontSize:"15px",marginTop:"-3px"}}/> {intl.formatMessage({ id: 'no' })}</>}</TableCell>
                    <TableCell align="center">{r.init_balance}</TableCell>
                    <TableCell align="center">{currentLanguageCode == "en-us"? r.en_fare_media_type_name : r.ar_fare_media_type_name}</TableCell>
                    <TableCell align="center">{currentLanguageCode == "en-us"? r.en_issuer_name : r.ar_issuer_name}</TableCell>
                    <TableCell align="center">{currentLanguageCode == "en-us"? r.en_org_name : r.ar_org_name}</TableCell>
                    <TableCell align="center">{parseFloat(r.selling_price)}</TableCell>
                    {/*<TableCell align="center">{r.user_phone}</TableCell>
                    <TableCell align="center">{r.user_email}</TableCell>*/}
                    <TableCell align="center">{convertToLocalTime(r.created_at)}</TableCell>
                    <TableCell align="center">{convertToLocalTime(r.expiration_date)}</TableCell>
                    {hasWriteAccess && 
                       
                          <TextCell
                          label={<EditIcon className="editAndDeleteIcon" onClick={(e) => moveToEditPage(r.id, e)} />}
                        align="center"
                        />
                    }
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center", padding: "15px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        )}
      </div>


    </div>
  )
}

export default FareMediaTable