// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Suspense } from 'react'
// import "../../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';

const TableAnalysis = ({ transactionsNumber, transactionsTotalAmount }) => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const intl = useIntl();
  return (
    <div className="table-data-more-analysis">
      <span style={{ direction: "ltr" }}>
        <span className='show-span'>
          <bdi style={{ margin: "5px" }}> {intl.formatMessage({ id: 'transactionsNumber' })} </bdi>
        </span>
        {transactionsNumber}
        <span style={{ marginLeft: "8px", marginRight: "8px" }}>   </span>
    
      </span>

    </div>
  )

};

export { TableAnalysis };
