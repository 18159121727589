// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';

import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Divider,
} from '@material-ui/core';

import { LanguageSelectBox } from 'app/components/shared/language-selectbox.tsx';
import { UserDropdown } from 'app/components/user-dropdown';
import { DIRECTIONS } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';

import ConfigurationsIcon from './configurations-icon.svg';
import OperationsIcon from './operations-icon.svg';
import zoningIcon from './zones-icon.svg';
import financialControlIcon from 'assets/financial-control.svg';
import CustomerSupport from './customer-support.svg';
import CardsManagement from './cards-management.svg';
import Reports from './reports.svg';
export const SystemIcon = {
  Operations: OperationsIcon,
  Configurations: ConfigurationsIcon,
  financialControl: financialControlIcon,
  zoning:zoningIcon,
  CustomerSupport:CustomerSupport,
  CardsManagement:CardsManagement,
  Reports:Reports
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeader: {
      fontSize: 24,
      fontWeight: 'bold',
      marginBottom: 14,
      height: 44,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
    },
  })
);

const SystemHeader: React.FC<{
  headerTitle: string;
  headerIcon: string;
}> = ({ headerTitle, headerIcon }) => {
  const classes = useStyles();
  const { direction, locale, setLocale } = useContext(LanguageContext);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) =>
    setLocale?.(event.target.value as string);

  return (
    <Typography className={classes.sectionHeader} variant="h2" dir={direction}>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={headerIcon}
          alt="System Icon"
          style={{
            marginRight: direction === DIRECTIONS.LTR ? 14 : 0,
            marginLeft: direction === DIRECTIONS.RTL ? 14 : 0,
          }}
        />

        {headerTitle}
      </span>

      <div style={{ display: 'flex' }}>
        <LanguageSelectBox
          value={locale.toLowerCase()}
          handleChange={handleChange}
        />

        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: '23px 24px 17px 22px' }}
        />

        <UserDropdown />
      </div>
    </Typography>
  );
};

export { SystemHeader };
