// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasReadAccessToMasterConfigSubSystem, hasWriteAccessToMasterConfigSubSystem } from 'selectors';

import { CustomerSupportHome } from './customerSupportHome';
import { useIntl } from 'react-intl';
import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { CardContainer } from 'app/components/shared/card-container';
import { SystemHeader ,SystemIcon} from 'app/components/shared/system-header';

const CustomerSupport: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const canViewCustomerSupport = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'CustomerSupport')
  );

  return (
    <>
    <SystemHeader
    headerTitle={intl.formatMessage({ id: 'customerSupport' })}
    headerIcon={SystemIcon.CustomerSupport}
  />
    <CardContainer >
    <Switch>
        <Redirect from={baseURL} exact to={`${baseURL}/CustomerSupportHome`} />

        <Route path={`${baseURL}/CustomerSupportHome`}>
          {canViewCustomerSupport ? <CustomerSupportHome /> : <NotAuthorized />}
        </Route>
        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    
    </CardContainer>
    </>
  );
};

export { CustomerSupport };
