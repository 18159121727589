// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { combineReducers } from 'redux';

import { admins } from 'reducers/admins.reducer';
import { alert } from 'reducers/alert.reducer';
import { authentication } from 'reducers/authentication.reducer';
import { devices } from 'reducers/devices.reducer';
import { drivers } from 'reducers/drivers.reducer';
import { groups } from 'reducers/groups.reducer';
import { organizations } from 'reducers/organizations.reducer';
import { permissions } from 'reducers/permissions.reducer';
import { routes } from 'reducers/routes.reducer';
import { stations } from 'reducers/stations.reducer';
import { stops } from 'reducers/stops.reducer';
import { tripSales } from 'reducers/trip-sales.reducer';
import { vehicleClasses } from 'reducers/vehicle-classes.reducer';
import { vehicleTypes } from 'reducers/vehicle-types.reducer';
import { vehicles } from 'reducers/vehicles.reducer';
import { zoning } from 'reducers/zoning.reducer';
import { customerSupport } from 'reducers/customer-support.reducer';
const rootReducer = combineReducers({
  admins,
  alert,
  authentication,
  drivers,
  organizations,
  permissions,
  routes,
  groups,
  tripSales,
  stops,
  devices,
  vehicles,
  vehicleTypes,
  vehicleClasses,
  stations,
  zoning,
  customerSupport
});

export default rootReducer;
