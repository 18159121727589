// @ts-nocheck
import axios from "axios";
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import { Link, NavLink, useHistory } from "react-router-dom";
  //import NavBar from '../components/NavBar';
  import { ToastContainer, toast } from 'react-toastify';
  import { useTranslation } from 'react-i18next'
  import "../style.css";
  //import Sidebar from '../components/Sidebar';
  import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
  import SearchIcon from '@mui/icons-material/Search';
  import cookies from 'js-cookie'
  import FilterAltIcon from '@mui/icons-material/FilterAlt';
  import CloseIcon from '@mui/icons-material/Close';
  import Loading from "../images/loading2.svg";
  import {
    Button,
    Popover,
    Typography,
  } from '@material-ui/core';
  import {
    createStyles,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

  } from '@material-ui/core';
  import { basicButtonStyles, tableRowStyles } from 'app/shared/styles';
  import { TextCell } from 'app/components/shared/table';

import { default as ReactSelect } from "react-select";

import { components } from "react-select";
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useGetFilterOrganizations } from 'hooks';
import { useGetFilterGroups } from 'hooks/useGetFilterGroups';
import { useTypedSelector } from 'helpers';
import { useSelector } from 'react-redux';
import { log } from 'console';

const Option= (props) => {
  return (
    <div >
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className='lab-fil'>{props.label} </label>
      </components.Option>
    </div>
  );
};
const useStyles = makeStyles(() =>
createStyles({
  table: { minWidth: 750, overflow: 'scroll', whiteSpace: 'nowrap' },
  addButton: { ...basicButtonStyles },
  row: { ...tableRowStyles },
})
);
const RoutesC: React.FC = () => {
  const intl = useIntl();
    const classes = useStyles();
    const organizationsGlobal = useGetFilterOrganizations({ entity: 'Routes' });
const groupsGlobal = useGetFilterGroups({ entity: 'Routes' });
    const [optionSelectedOrg, setOptionSelectedOrg] = useState<any[]>([]);
    const [optionSelectedGro, setOptionSelectedGro] = useState<any[]>([]);
    const [orgData, setOrgData] = useState<any[]>([]);
    const [groData, setGroData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true); // Loading state
      //const history.push = useNavigate();
      const { t } = useTranslation()
      const history = useHistory();
      const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
       //req
       const [page, setPage] = useState<any>(1);
       const [limit, setLimit] = useState<any>(10);
       const [searchBy, setSearchBy] = useState<any>("");
       const [searchBy2, setSearchBy2] = useState<any>("");
       const [flag, setFlag] = useState<any>(0);
      // const [flagClear, setFlagClear] = useState(0);
       //res
       const [rdata,getrdata] =  useState<any[]>([]);
       const [pageCount, setPageCount] = useState<any>(0);
       let user: any = '';
       let user2 :any= '';
       let userRefresh:any = '';
       
       const authData = localStorage.getItem("authData");
       
       if (authData !== null) {
         const parsedAuthData = JSON.parse(authData);
         user = parsedAuthData.userData;
         user2 = parsedAuthData.userToken;
         userRefresh = parsedAuthData.userToken;
       }
       
       
      useEffect(()=>{

      const makeRequest =  () => {
        let orgString = "";
        if (orgData.length > 0) {
          orgData?.forEach(function myFunction(item) {
            orgString += item?.value !== undefined ? `&q[organisation_id_in][]=${item?.value}` : "";
          });
        }
        

        let groString = "";
        if (groData.length > 0) {
          groData?.forEach(function (item) {
            groString += item?.value !== undefined ? `&q[route_groups_group_id_in][]=${item?.value}` : "";
          });
        }
        
   // let orgString=orgData[0]?.value !=undefined ?`&q[organisation_id_in][]=${orgData[0]?.value}` : ""
   // let groString=groData[0]?.value !=undefined ?`&q[route_groups_group_id_in][]=${groData[0]?.value}` : ""

let url=(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/routes/?page=${page}&page_count=${limit}&q[en_name_or_ar_name_or_code_cont]=${searchBy}`+orgString+groString)
console.log(url);   
setIsLoading(true); // Set loading to true when data is being fetched
    
//setTimeout(() => {
 axios.get(url,
  
    { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
   .then((res)=> {
  
  getrdata(res.data.response.data)
  
  setPageCount(Math.ceil((res.data.response.size)/limit));
  setIsLoading(false); // Set loading to false when data is fetched
     
      })
      .catch((err)=>{
console.log("API ERROR");
toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #r1`, {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
  }) 
  setIsLoading(false); // Set loading to false when data is fetched
         
      })
    //}, 3000);
  } 
      makeRequest();
   
      
        },[page,limit,searchBy,orgData,groData])
        function handlePrevious() {
          setPage((p) => {
            if (p === 1) return p;
            return p - 1;
          });
        }
      
        function handleNext() {
          setPage((p) => {
            if (p === pageCount) return p;
            return p + 1;
          });
        }
        function handleChangeLimit(lim) {
          setLimit(lim);
          setPage(1)
        }
        
   /* <NavBar/> */
   const navFunc = (id) =>{
    history.push("/zoning/zoningRoutes/routes/" + id);
   }
   /*search */
   /*useEffect(()=>{*/
   let makeRequestSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if(searchBy2.trim().length>0){

        if(searchBy2.trim().length > 150){
          toast.error("Value Is Very Long ...", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            }) 
        }else{
        
          setPage(1)
          setSearchBy(searchBy2);
        }
  
  
 }}
  let clearAll = async () => {

        setSearchBy("");
        setSearchBy2("");
        setPage(1);
        setOptionSelectedOrg([]);
        setOptionSelectedGro([]);
       
        setOrgData([])
        setGroData([])
        setFlag(0);
  
 }
   /*},[page,limit])*/
   /*filter */
   /*multi select */
  
   const [organisations, setOrganisations] = useState<{ value: any; label: any; }[]>([]);
   const [groups, setGroups]= useState<{ value: any; label: any; }[]>([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      const getOrganisationsData= ()=>{


        const formatedArr: { value: any; label: any; }[] = [];
        
        for (let i = 0; i < organizationsGlobal?.length; i++) {
          formatedArr.push({
            value: organizationsGlobal[i]?.id,
            label: organizationsGlobal[i]?.name,
          });
        }
        
        console.log(formatedArr);
        
           setOrganisations(formatedArr);
        
          
            }
          getOrganisationsData();
      const getGroupsData= ()=>{
          const formatedArr: { value: any; label: any; }[] = [];
  
  for (let i = 0; i < groupsGlobal?.length; i++) {
    formatedArr.push({
      value: groupsGlobal[i]?.id,
      label:groupsGlobal[i]?.name,
    });
  }
  console.log(formatedArr);
     setGroups(formatedArr);
  
    
      }
    getGroupsData();
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
 
  useEffect(()=>{
    console.log(groupsGlobal);
    console.log(organizationsGlobal);
  },[]);


  const ApplyFilter =async()=>{
 

        console.log(optionSelectedOrg);
        console.log(optionSelectedGro);
          setPage(1)
setFlag(1);
          setOrgData(optionSelectedOrg);
          setGroData(optionSelectedGro);
          handleClose();
  
  

   
    }
  

    return (
    
      <div className='general'>
       
       
       {/*<Sidebar/>*/}
      
          <div className='second'style={{flex:"1 1 auto", display:"flex", flexFlow:"column"}}>
       
          <h2 className='general-head'>
            <AssistantDirectionIcon style={{margin:"10px",fontSize:"33px"}}/>
          {intl.formatMessage({ id: 'Routes' })}</h2>
          { user && user2 ? <><div className='above-table' >
       
       
       <div style={{display:"flex"}}>
<form onSubmit={makeRequestSearch}>
       <div className="input-group">
    <input type="text" style={{borderRadius:"5px",border:"1px solid lightgray"}} onChange={(e) =>setSearchBy2(e.target.value) } value={searchBy2} className="form-control" placeholder={intl.formatMessage({ id: 'SearchByName' })} aria-label="Search By Name" aria-describedby="button-addon2"/>
    <button type="submit" style={{border:"0",padding:"5px 8px",borderRadius:"10px",margin:"0px 7px 0px 7px"}} className="btn btn-secondary"  id="button-addon2"><SearchIcon/></button>

  </div>
  
  </form>
  <div>
  <Button className='filter-hover' aria-describedby={id} 
  style={{textTransform:"capitalize",border:"1px solid lightgray",
  borderRadius:"15px",color:flag==1&&(orgData?.length>0||groData?.length>0) ?"white":"#7A7B7B",backgroundColor: flag==1&&(orgData?.length>0||groData?.length>0) ?"#2891CD":"white",padding:"6px 12px"}} 
  onClick={handleClick}>
  <FilterAltIcon/>{intl.formatMessage({ id: 'Filter' })} 
  </Button>
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    
  >
    <Box sx={{ p: 2 }} style={{ position: "relative", overflowY: "scroll" ,backgroundColor:"white"}}>
      <Typography >
      <button onClick={handleClose}
       style={{border:"0",
  borderRadius:"10px",color:"#7A7B7B",backgroundColor:"white",padding:"3px",position:"absolute",top:"8px",right:"19px",cursor:"pointer"}} 
  ><CloseIcon style={{fontSize:"30px"}}/></button>
  <div style={{marginTop:"40px"}}>
    <div style={{border:"1px solid #E1E0E0",padding:"10px 10px 40px 10px",borderRadius:"12px"}}>
    <h6 className='head-filter'>{intl.formatMessage({ id: 'Organization' })}</h6>
      <span
        style={{width:"270px",display:"inline-block"}}
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
        className='filter-span'
      >
   <ReactSelect
          options={organisations}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
        
          onChange={setOptionSelectedOrg}
          allowSelectAll={true}
          defaultValue={optionSelectedOrg}
        />
        
      </span> 
   <h6 className='head-filter'>{intl.formatMessage({ id: 'Groups' })}</h6>
   <span
        style={{width:"270px",display:"inline-block"}}
        data-toggle="popover"
        data-trigger="focus"
        data-content="Please selecet account(s)"
        className='filter-span'
      >
      <ReactSelect
          options={groups}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option
          }}
          onChange={setOptionSelectedGro}
          allowSelectAll={true}
          defaultValue={optionSelectedGro}
        />

      </span></div>
      <button type="button"  onClick={ApplyFilter}   style={{
  borderRadius:"25px",marginTop:"25px",padding:"4px 12px"

  ,border:"0",fontWeight:"500",backgroundColor:"green",color:"white",cursor:"pointer"}}className="btn btn-success">{intl.formatMessage({ id: 'Apply' })}</button>


      </div>
      </Typography>  </Box>
  </Popover></div>
  { searchBy.trim().length>0 ||(flag==1&&(orgData?.length>0||groData?.length>0)) ?
    <button className='clearDiv' style={{textTransform:"capitalize",padding:"2px 10px 5px 10px",margin:"10px",height:"30px",
  borderRadius:"14px",color:"white",backgroundColor:"#2891CD",border:"0",marginTop:"17px"}} 
 onClick={clearAll} > {intl.formatMessage({ id: 'clearAll2' })}</button>:<></>}

       </div>
        <div style={{direction:"ltr"}}> 
        {rdata?.length>0 ?<>
     { currentLanguageCode == "en-us"? <span style={{margin:"10px",direction:"ltr"}}>
  {page}  <span className='show-span'><bdi style={{margin:"10px"}}> {intl.formatMessage({ id: 'From' })} </bdi></span>  {pageCount} </span>
  
  : <span style={{margin:"10px",direction:"ltr"}}>
  { pageCount}  <span className='show-span'><bdi style={{margin:"10px"}}> {intl.formatMessage({ id: 'From' })} </bdi></span>  {page} </span>}
  <button className='pageArrow'  disabled={page === 1} onClick={handlePrevious}>
  <span style={{direction:"ltr"}}>   
  <KeyboardArrowLeftIcon style={{fontSize:"30px"}}/></span>
     </button>
     <button className='pageArrow' disabled={page === pageCount} onClick={handleNext}>
      <span style={{direction:"ltr"}}>   
       <KeyboardArrowRightIcon style={{fontSize:"30px"}}/></span>
  
     </button>
  </>: <></>}
     <span style={{margin:"10px",direction:"ltr"}}><bdi>
        <span className='show-span'style={{margin:"10px",direction:"ltr"}}> <bdi>
         {intl.formatMessage({ id: 'Show' })}  </bdi></span>
       <select className='show-select'style={{direction:"ltr"}} onChange={(e)=>handleChangeLimit(e.target.value)}>
  
  <option value="10">10</option>
  <option value="15">15</option>
  <option value="25">25</option>
  <option value="50">50</option>
  <option value="100">100</option>
  <option value="150">150</option>
  </select></bdi>
  </span>
  </div>
  </div></>:<></>}
     { user && user2 ?
        <div className="parentTable  " >
  
  <Table
className={classes.table}
size="small"
aria-label="zoning Routes Table"
>
<TableHead style={{border:"0"}}>
  <TableRow style={{border:"0"}}>
    <TableCell align="center">
    {intl.formatMessage({ id: 'EnglishName' })}
    </TableCell>
    <TableCell align="center">
    {intl.formatMessage({ id: 'ArabicName' })}
    </TableCell>

    <TableCell>
    <th> {intl.formatMessage({ id: 'Code' })}</th>
    </TableCell>

    <TableCell>
    {intl.formatMessage({ id: 'Organization' })}
    </TableCell>

    <TableCell align="center">
    {intl.formatMessage({ id: 'Groups' })}
    </TableCell>
  </TableRow>
</TableHead>
<TableBody>
    { 
    isLoading ? (
      <tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>
        <div style={{textAlign:"center",marginTop:"10px"}}>
<img src={Loading} alt="loading"  style={{width:"150px"}} />
</div>
        </td></tr>
    ) : 
  
    rdata?.length>0 ? 
       rdata?.map((r,i) =>

          <TableRow key={r.id} onClick={()=>navFunc(r.id)} className={classes.row}>
          <TextCell
    label={r.en_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
            <TextCell
    label={r.ar_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
              <TextCell
    label={r.code}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
                <TextCell
    label={currentLanguageCode == "ar-eg"? r.organisation.ar_name : r.organisation.en_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />
                <TextCell
    label={r.groups[0].en_name}
    align="center"
    style={{ fontFamily: 'Cairo' }}
  />        
  
  </TableRow>)
  :<tr> <td colSpan={5}style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</td></tr>
    } 
   </TableBody>
  </Table>
        </div> : <h2>
        <center> {intl.formatMessage({ id: 'LoginAgain' })} </center>
          </h2>}
  
        </div>
        </div>    
    )
  }
  
  export default RoutesC