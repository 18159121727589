// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import "../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
import SearchIcon from '@mui/icons-material/Search';
const SearchSection = ({clickSearchBtn,setInputSearchValue,inputSearchValue,transactions,clearAll,isLoading}) => {
      const dispatch = useDispatch();
      const match = useRouteMatch();
      const intl = useIntl();
return(
<div className="parent-search-input">    
<form onSubmit={clickSearchBtn}>
<div className="input-group input-group-parent">
      <input  type="text" style={{borderRadius:"5px",border:"1px solid #adb5bc"}} onChange={(e) =>setInputSearchValue(e.target.value) } value={inputSearchValue} className="search-input form-control" placeholder={intl.formatMessage({ id: 'SearchByFareMediaSerial' })} aria-label="Search By Name" aria-describedby="button-addon2"/>
      {isLoading?<span style={{margin:"11px"}}>{intl.formatMessage({ id: 'NoMessageFound' })}</span>:<button type="submit" style={{border:"0",padding:"5px 30px",borderRadius:"10px",margin:"0px 7px 0px 7px"}} className="btn btn-secondary"  id="button-addon2"><SearchIcon/></button>}
</div>
</form>
{ inputSearchValue.trim().length>0 || transactions?.length>0 ?
<button className='resetBtn' style={{textTransform:"capitalize",padding:"4.5px 10px 5px 10px",margin:"10px",height:"30px",
   borderRadius:"14px",color:"#2891CD",backgroundColor:"white",border:"1px solid #2891CD",marginTop:"17px"}} 
   onClick={clearAll} > {intl.formatMessage({ id: 'clearAll' })}</button>
:<></>}

</div>
   )
  
};

export { SearchSection };
