// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import "../../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
import DatePicker from "react-datepicker";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Button,
    Popover,
  } from '@material-ui/core';
  import { Box } from '@mui/material';
const TableFilter = ({
        isFilterClicked,
        filterStartDateValue,
        clearFilterResultOnly,
        transactions,
        filterPopoverClickButton,
        idOfPopUp,
        open,
        anchorEl,
        handleClose,
        runDateTimeFilter,
        startDate,
        setStartDate,
        endDate,
        setEndDate
}) => {
      const dispatch = useDispatch();
      const match = useRouteMatch();
      const intl = useIntl();
return(
<div className="filter-parent">
      { (isFilterClicked==1&&(filterStartDateValue)) ?
    <button className='ResetFilterBtn' style={{textTransform:"capitalize",padding:"4.5px 10px 5px 10px",margin:"10px",height:"30px",
            borderRadius:"14px",color:"gray",backgroundColor:"white",border:"1px solid gray",marginTop:"17px"}} 
            onClick={clearFilterResultOnly} > {intl.formatMessage({ id: 'ResetFilter' })}</button>
    :<></>}   
{(transactions?.length>0 || (isFilterClicked==1&&(filterStartDateValue))) ?
        <>        
{<Button className='filter-hover filter-open-btn' aria-describedby={idOfPopUp} 
  style={{textTransform:"capitalize",border:(isFilterClicked==1&&(filterStartDateValue)) ? "0": "1px solid lightgray",margin:(isFilterClicked==1&&(filterStartDateValue))?"-1px 10px -5px 10px":"10px 10px -5px 10px",
  borderRadius:"15px",color:(isFilterClicked==1&&(filterStartDateValue)) ?"white":"#7A7B7B",backgroundColor: (isFilterClicked==1&&(filterStartDateValue))  ?"#2891CD":"white",padding:"6px 12px"}} 
  onClick={filterPopoverClickButton}>
  <FilterAltIcon/>{intl.formatMessage({ id: 'Filter' })} 
  </Button>}
  <Popover
    id={idOfPopUp}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    PaperProps={{
      style: { overflow: 'visible' },
    }}
    
  >
    <Box sx={{ p: 2 }} style={{ position: "relative",backgroundColor:"white"}}>
          
        <form onSubmit={runDateTimeFilter}>
    <div className="parentEachDatePicker">
      <label className='DatePickerLabel'>{intl.formatMessage({ id: 'startDate' })}</label>
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        showTimeSelect
        dateFormat="MM/dd/yyyy, hh:mm:ss a"
        className="datepicker-custom"
      />
  </div>
    <div className="parentEachDatePicker">
      <label className='DatePickerLabel'>{intl.formatMessage({ id: 'endDate' })}</label>
      <DatePicker
        selected={endDate}
        onChange={date => setEndDate(date)}
        showTimeSelect
        dateFormat="MM/dd/yyyy, hh:mm:ss a"
        className="datepicker-custom"
      />

    </div>
    <button  className="datepicker-filter-btn"  type="submit"> <FilterAltIcon/>{intl.formatMessage({ id: 'Filter' })} </button>
  
</form></Box>
  </Popover></>: <></>}    

</div>
   )
  
};

export { TableFilter };
