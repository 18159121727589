// reducers/zoning.reducer.ts
const initialState = {
	fareMediaInfo: null,
	boolValue:false
  };
  
  const customerSupport = (state = initialState, action) => {
	switch (action.type) {
	  case "storeFareMediaInfo":
		return {
		  ...state,
		  fareMediaInfo: action.payload
		};
		case "visabilityOfErrorPage":
			return {
			  ...state,
			  boolValue: action.payload
			};
	  default:
		return state;
	}
  };
  
  export {customerSupport};
  