// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
import { useTypedSelector } from 'helpers';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const ViewMerchant = () => {
  const [merchantName, setMerchantName] = useState('');
  const [merchantEnName, setMerchantEnName] = useState('');
  const [merchantStatus, setMerchantStatus] = useState(1); // Default selected status
  const [merchantPOSCount, setMerchantPOSCount] = useState(null); // Default selected status
  const [statuses, setStatuses] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us';
  const intl = useIntl();
  const history = useHistory();
  const [deleteResponse, setDeleteResponse] = useState(null); 
  let userPersonalData = '';
  let userAuthData = '';
  const authData = localStorage.getItem("authData");
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    userPersonalData = parsedAuthData.userData;
    userAuthData = parsedAuthData.userToken;
  }

  // Fetch merchant data
  useEffect(() => {
    const fetchMerchant = async () => {
      setIsFormLoading(true);
      setError(null);
      try {
        let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/getOneMerchant`;
        const response = await axios.post(url, {
          MERCHANT_ID: id,
        }, {
            headers: {
              'access-token': userAuthData?.access_token,
              'uid': userAuthData?.uid,
              'client': userAuthData?.client,
            },
          });
        const merchant = response.data.data;
        setMerchantName(merchant.merchant_name);
        setMerchantEnName(merchant.merchant_en_name);
        setMerchantStatus(merchant.merchant_status);
        setMerchantPOSCount(merchant.pos_count);
      } catch (error) {
        setError(`Failed to fetch merchant [${error}]`);
        toast.error(`Failed to fetch merchant [${error}]`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } finally {
        setIsFormLoading(false);
      }
    };

    fetchMerchant();
  }, [deleteResponse]);

  const hasWriteAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'Merchants')
  );
  const deleteMerchant = async (merchantName,merchantEnName,id) => {
     setIsLoading(true);
     try {
       let url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/merchants/updateMerchant`;
       const response = await axios.post(url, {
         MERCHANT_STATUS: 3,
         MERCHANT_NAME: merchantName,
         MERCHANT_EN_NAME: merchantEnName,
         MERCHANT_ID: id,
       }, {
         headers: {
           'access-token': userAuthData?.access_token,
           'uid': userAuthData?.uid,
           'client': userAuthData?.client,
         },
       });
       setDeleteResponse(response.data)
       toast.success('Merchant Deleted successfully!', {
         position: 'top-right',
         autoClose: 5000,
         hideProgressBar: true,
         closeOnClick: true,
         toastStyle: {
           backgroundColor: '#F1662D',
           color: 'white',
         },
       });
       
    
     } catch (error) {
       if (error && error.response && error.response.data && error.response.data.message) {
       
         toast.error(`Failed to Delete merchant [${error.response.data.message}]`, {
           position: 'top-right',
           autoClose: 5000,
           hideProgressBar: true,
           closeOnClick: true,
         });
       } else {
        
         toast.error(`Failed to Delete merchant [${error}]`, {
           position: 'top-right',
           autoClose: 5000,
           hideProgressBar: true,
           closeOnClick: true,
         });
       }
     } finally {
       setIsLoading(false);
     }
   };
  return (
    <>
      <Box sx={{  marginTop: -5, padding: 4 }}>
        <Typography sx={{ color: '#252733',fontWeight:"bold",marginBottom:"16px" }}>
          {intl.formatMessage({ id: 'MerchantInfo' })}
        </Typography>
        
        {isFormLoading ? (
          <CircularProgress  style={{display:"block"}}/>
        ) : (

          <>
            <div className='table-view-item-info'>
                <div className="name-and-actions-btns-sec">
                    <h5 className="title-item-sec">
                        {merchantEnName} - {merchantName}
                    </h5>
                    <div className="actions-btns-sec">
                    {hasWriteAccess && 
                        <> <button className="styleAddBtn" style={{position:"inherit",marginTop:"-10px",paddingBottom:"7px",paddingTop:"8px"}}  onClick={()=>history.push(`/CardsManagement/merchants/${id}/edit?came_from=one`)}>
                           <EditIcon style={{fontSize:"24px",paddingRight:"3px",paddingLeft:"3px"}} /> {intl.formatMessage({ id: 'edit' })}
                        </button>
                        {merchantStatus !=3 &&<><button  className="styleAddBtn"  data-bs-toggle="modal" style={{backgroundColor:"#D54C5E",position:"inherit",marginLeft:"5px",marginRight:"5px",marginTop:"-10px",paddingBottom:"7px",paddingTop:"8px"}} 
                           data-bs-target={"#exampleModal2"+ id }>
                          <DeleteIcon style={{fontSize:"24px",paddingRight:"3px",paddingLeft:"3px"}}/> {intl.formatMessage({ id: 'Delete' })}  </button>
                        <div className="modal fade" style={{zIndex:"9999969"}} id={"exampleModal2"+ id} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                             
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                <h5>  {currentLanguageCode == "en-us" ? `Are You Sure You Want To Delete ${merchantEnName} - ${merchantName}` : `هل أنت متأكد انك تريد حذف ${merchantName} - ${merchantEnName}`}</h5>
                                </div>
                              <div className="modal-footer" style={{direction:currentLanguageCode == "en-us" ? "ltr":"rtl"}}>
                              <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal">{intl.formatMessage({ id: 'Cancel' })} </button>
                                <button type="button" 
                                className="btn btn-danger"data-bs-dismiss="modal"
                                
                              onClick={()=>deleteMerchant(merchantName,merchantEnName,id)}>{intl.formatMessage({ id: 'Delete' })} </button>
                          
                              </div>
                            </div>
                          </div>
                        </div></>}</>}

                    </div>
                </div>
                <div className="data-view-sec">

                <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'EnglishName' })}
                        </div>
                        <div className="value-of-one-info">
                           {merchantEnName}
                        </div>
                    </div>

                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'ArabicName' })}
                        </div>
                        <div className="value-of-one-info">
                           {merchantName}
                        </div>
                    </div>
                   
                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'MerchantStatus' })}
                        </div>
                        <div className="value-of-one-info">
                           
                           {merchantStatus == 1 ?<CheckCircleIcon style={{color:"#81D135"}}/>:merchantStatus == 2 ?<CheckCircleIcon style={{color:"black"}}/>:<CheckCircleIcon style={{color:"red"}}/>}
                        </div>
                    </div>

                    <div className="one-info">
                        <div className="title-of-one-info">
                            {intl.formatMessage({ id: 'POSCount' })}
                        </div>
                        <div className="value-of-one-info">
                           {merchantPOSCount}
                        </div>
                    </div>
                </div>
            </div>
       
          </>
        )}
         <button
           className='cancelBtnStyle'
           style={{marginTop:"20px"}}
            onClick={() => history.push(`/CardsManagement/merchants/`)}
          >
            {intl.formatMessage({ id: 'BackToMerchants' })}
          </button>
      </Box>
      <ToastContainer /></>
    
  );
};

export default ViewMerchant;
