// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasReadAccessToMasterConfigSubSystem } from 'selectors';
import { Merchants } from 'app/cards-management/merchants';
import { MerchantPOS } from './merchant-pos';
import { ChargingTransactions } from './charging-transactions';
import { FareMedia } from './fare-media';
const CardsManagement: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const canViewMerchants = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'Merchants')
  );
  const canViewMerchantPOS = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );
  const canViewChargingTransactions = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'ChargingTransactions')
  );
  const canViewFareMedia = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'FareMedia')
  );
  const getInitialRoute = () => {
    if (canViewMerchants) {
      return `${baseURL}/merchants`;
    }
    if (canViewMerchantPOS) {
      return `${baseURL}/merchantPOS`;
    }
    if (canViewChargingTransactions) {
      return `${baseURL}/chargingTransactions`;
    }
    if (canViewFareMedia) {
      return `${baseURL}/fareMedia`;
    }
    return `${baseURL}/merchants`;
  };

  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'CardsManagement' })}
        headerIcon={SystemIcon.CardsManagement}
      />

      <Switch>
        <Redirect from={baseURL} exact to={getInitialRoute()} />

        <Route path={`${baseURL}/merchants`}>
          {canViewMerchants ? <Merchants /> : <NotAuthorized />}
        </Route>
        <Route path={`${baseURL}/merchantPOS`}>
          {canViewMerchantPOS ? <MerchantPOS /> : <NotAuthorized />}
        </Route>
        <Route path={`${baseURL}/chargingTransactions`}>
          {canViewChargingTransactions ? <ChargingTransactions /> : <NotAuthorized />}
        </Route>
        <Route path={`${baseURL}/fareMedia`}>
          {canViewFareMedia ? <FareMedia /> : <NotAuthorized />}
        </Route>
        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { CardsManagement };
