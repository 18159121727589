// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
import React, { ReactElement, useContext, useRef, useState } from 'react';

import {
  makeStyles,
  createStyles,
  Badge,
  TableCell,
  Tooltip,
  Chip,
} from '@material-ui/core';
import { isString } from 'formik';
import { useIntl } from 'react-intl';

import { LOCALES } from 'app/i18n-locale/locales-constants';
import { LanguageContext } from 'context/intl.context';
import { isArabicText, useResizeEffect } from 'helpers';
import { Group } from 'types';

import RestrictedIcon from './restricted-icon.svg';

const useStyles = makeStyles(() =>
  createStyles({
    restrictedWrapper: {
      display: 'flex',
      alignItems: 'center',
      color: '#979797',
    },
    lockIcon: {
      marginRight: 11,
    },
    activeBadge: { backgroundColor: '#81D135', left: -12 },
    disabledBadge: { backgroundColor: '#CE0B24', left: -12 },
    cell: {
      paddingLeft: 30,
    },
    status: { marginLeft: 28 },
    groupNameStyle: {
      marginRight: 4,
      backgroundColor: '#fff',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    groupsContainer: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    statusContainer: {
      maxWidth: 50,
      border: '1px solid #E5E5E5',
    },
    tooltip: {
      backgroundColor: '#4b506d',
      padding: '18px 15px',
      fontSize: 14,
      maxWidth: 230,
    },
    tooltipPlacementBottom: {
      marginTop: '10px',
    },
    textCell: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })
);

const RestrictedCell: React.FC<{ align?: 'flex-start' | 'center' }> = ({
  align = 'flex-start',
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { locale } = useContext(LanguageContext);

  const dir = locale?.toLowerCase() === LOCALES.ARABIC ? 'rtl' : 'ltr';

  return (
    <div
      className={classes.restrictedWrapper}
      dir={dir}
      style={{ justifyContent: align }}
    >
      <img src={RestrictedIcon} alt="restricted" className={classes.lockIcon} />

      <>&lt;{intl.formatMessage({ id: 'restricted' })}&gt;</>
    </div>
  );
};

const StatusCell: React.FC<{ isDisabled?: boolean; showLabel?: boolean }> = ({
  isDisabled,
  showLabel,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <TableCell className={classes.cell}>
      <Badge
        classes={{
          badge: isDisabled ? classes.disabledBadge : classes.activeBadge,
        }}
        badgeContent=""
      />

      {showLabel && (
        <span className={classes.status}>
          {intl.formatMessage({ id: isDisabled ? 'disabled' : 'enabled' })}
        </span>
      )}
    </TableCell>
  );
};

const GroupsCell = ({ groups = [] }: { groups: Group[] }) => {
  const classes = useStyles();
  const { locale } = useContext(LanguageContext);

  const joinedGroups = groups
    ?.map(group =>
      locale?.toLowerCase() === LOCALES.ARABIC ? group.ar_name : group.en_name
    )
    .join(', ');

  return (
    <TableCell style={{ maxWidth: 300 }}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }}
        title={groups?.length ? <div>{joinedGroups}</div> : ''}
      >
        <div className={classes.groupsContainer}>
          {groups.map(elem => {
            if (elem.en_name) {
              return (
                <Chip
                  label={
                    locale?.toLowerCase() === LOCALES.ARABIC
                      ? elem.ar_name
                      : elem.en_name
                  }
                  variant="outlined"
                  key={elem.id}
                  className={classes.groupNameStyle}
                />
              );
            }

            return null;
          })}
        </div>
      </Tooltip>
    </TableCell>
  );
};

const TextCell: React.FC<{
  label: string | ReactElement;
  showTooltip?: boolean;
  style?: React.CSSProperties;
  maxWidth?: number;
  align?: 'left' | 'center' | 'right';
}> = ({ label, showTooltip = true, style = {}, maxWidth, align }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasOverFlown, setHasOverFlown] = useState(false);
  const classes = useStyles();

  const dir = isArabicText(label) ? 'rtl' : 'ltr';

  const handleResize = () => {
    const element = ref.current;
    let hasOverflowingChildren = false;
    if (element) {
      hasOverflowingChildren = element.offsetWidth < element.scrollWidth;
    }
    setHasOverFlown(hasOverflowingChildren);
  };

  useResizeEffect(handleResize, [ref]);

  const canShowTooltip = hasOverFlown && showTooltip && isString(label);

  return (
    <TableCell dir={dir} style={{ maxWidth, ...style }} align={align}>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }}
        title={canShowTooltip ? <div>{label}</div> : ''}
      >
        <div ref={ref} className={classes.textCell}>
          {label}
        </div>
      </Tooltip>
    </TableCell>
  );
};

export { RestrictedCell, StatusCell, GroupsCell, TextCell };
