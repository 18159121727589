// @ts-nocheck
import axios from "axios";
import React, { Suspense, useEffect, useState } from 'react'
import { NavLink, useHistory, useParams, Link } from "react-router-dom";
//import NavBar from '../components/NavBar';
import OurMap from '../components/Map';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router-dom";
//import Sidebar from '../components/Sidebar';
import cookies from 'js-cookie'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import { Fragment } from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import Loading from "../images/loading2.svg";
import { useIntl } from 'react-intl';
import ErrorComponent from '../components/ErrorComponent';
  const InfoOfAxis: React.FC = () => {
    const intl = useIntl();
  let params = useParams();
  const history = useHistory();
    const { t } = useTranslation()
    const [allAxisData,setAllAxisData] =  useState<any[]>([]);
    const [lineStringSearch,setLineStringSearch] =  useState<any>(0);
    const [lineStringInfo,setLineStringInfo] =  useState<any>({});
    const [TMSRouteData,setTMSRouteData] =  useState<any>({});
    const [allStops,setAllStops] = useState<any[]>([]);
    const [check,setCheck] =  useState<any>(0);
    const [boundsIsZones,setBoundsIsZones] = useState<any>(0);
    const [goToErrorComponent,setGoToErrorComponent] = useState<any>(false);
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [errorComponentData,setErrorComponentData] = useState<any>({code:'10000',message:'AnErrorHappend'});
    const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
    let user: any = '';
    let user2 :any= '';
    let userRefresh:any = '';
    
    const authData = localStorage.getItem("authData");
    
    if (authData !== null) {
      const parsedAuthData = JSON.parse(authData);
      user = parsedAuthData.userData;
      user2 = parsedAuthData.userToken;
      userRefresh = parsedAuthData.userToken;
    }
    let { search} = useLocation();
    let routeid =new URLSearchParams(search).get("routeid") ||  sessionStorage.getItem("routeid") 
    let axisid = new URLSearchParams(search).get("axisid") || sessionStorage.getItem("axisid") 
    let axiscode = new URLSearchParams(search).get("axiscode") || sessionStorage.getItem("axiscode") 
    useEffect(()=>{

      if(!routeid ||  !axisid || !axiscode){
       // if(!routeidSP ||  !axisidSP || !axiscodeSP){
          //history.push(`/zoning/zoningRoutes/Error`);
          setGoToErrorComponent(true)
          setErrorComponentData({code:'#965857578',message:'AnErrorHappend'})
       /* }else{
      // Set sessionStorage
      sessionStorage.setItem('routeid', routeidSP);
      sessionStorage.setItem('axisid', axisidSP);
      sessionStorage.setItem('axiscode', axiscodeSP);
       routeid = sessionStorage.getItem("routeid") 
       axisid = sessionStorage.getItem("axisid") 
       axiscode =sessionStorage.getItem("axiscode") 
        } */    
        }else{
          const searchParams = new URLSearchParams();
          // Set query parameters
          searchParams.set('routeid', routeid);
          searchParams.set('axisid', axisid);
          searchParams.set('axiscode', axiscode);
          sessionStorage.setItem('routeid', routeid);
          sessionStorage.setItem('axisid', axisid);
          sessionStorage.setItem('axiscode', axiscode);
          // Update the URL with the new query parameters
          history.push({
            pathname: '/zoning/zoningRoutes/axis',
            search: `?${searchParams.toString()}`,
          });
        }
    },[])

  

  const [permissionValue, setPermissionValue] = useState<any>(true);
    useEffect(()=>{
 // For Auth is this orgnization or group Avaiable to see its data
 const checkAuthOfThisOrgOrGroup= (currentOrgID,currentGroupsArray)=>{
  if(currentOrgID == undefined || currentGroupsArray ==undefined ){
   // history.push("/zoning/zoningRoutes/Error");
   setGoToErrorComponent(true)
   setErrorComponentData({code:'#965857599',message:'AnErrorHappend'})
   }
     axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/general_permissions`,

    { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
   .then((res)=> {

//is superAdmin

}).catch((err)=>{
  if (err.response && err.response.status === 403) {
 //is not super
 const checkGroupAuthAndReadOrWrite= (data) => {
  // Find the groups objects that matches the currentGroupsArray
  const groups = data.filter((item) => currentGroupsArray.some(group => group.id === item.group.id));
 
 console.log(groups);
 
  // Assuming 'groups' is the array you provided:
 // Assuming 'groups' is the array you provided:
 if (groups && groups.length > 0) {
   // Find the first group that has the "Fares" sub-system
   const faresGroup = groups.find(group => group.sub_systems.some(sub => sub.sub_system_name === 'Fares'));
 
   // If "Fares" sub-system is found in the group
   if (faresGroup) {
     // Find the "Fares" sub-system in the group
     const faresSubSystem = faresGroup.sub_systems.find(sub => sub.sub_system_name === 'Fares');
 
     // Check if the "Fares" sub-system has "action_name" with the value "write"
     if (faresSubSystem) {
       const writeAccess = faresSubSystem.allowed_actions.some(action => action.action_name === 'write');
      
       
       return writeAccess;
     }
   }
 }
// If "Fares" sub-system is not found or it doesn't have "action_name" with the value "write"
// Return false (or handle it as needed)

setGoToErrorComponent(true)
setErrorComponentData({code:'#403',message:'YouHaveNoAccess'})

return false;
}
 const checkOrgAuthAndReadOrWrite= (data,groupsData) => {
  //organisations_permission_level,groups_permission_level
 // Find the organization object that matches the currentOrgID
 const organization = data.find((item) => item.organisation.id === currentOrgID);

 // If the organization exists, check for "Fares" sub-system
 if (organization) {
   const faresSubSystem = organization.sub_systems.find((sub) => sub.sub_system_name === 'Fares');

   // If "Fares" sub-system is found, check if it has "action_name" with the value "write"
   if (faresSubSystem) {
     const writeAccess = faresSubSystem.allowed_actions.some((action) => action.action_name === 'write');
     return writeAccess;
   }else{
   return checkGroupAuthAndReadOrWrite(groupsData) 
   }
 }else{
  return checkGroupAuthAndReadOrWrite(groupsData)
 }
};
const checks= ()=>{
   axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/admin_general_permissions/get_full_permissions?admin_id=${user.id}`,

  { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
 .then((res)=> {
 console.log(res.data.response.organisations_permission_level);
 console.log(res.data.response.groups_permission_level);
 setPermissionValue(checkOrgAuthAndReadOrWrite(res.data.response.organisations_permission_level,res.data.response.groups_permission_level))
 console.log( "write : ",checkOrgAuthAndReadOrWrite(res.data.response.organisations_permission_level,res.data.response.groups_permission_level));
    })
    .catch((err)=>{console.log("API ERROR");
    toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ioa1`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      }) 
  })
}
checks()
  }
})
}
  
      const getAxisInfo =  () => {
        setIsLoading(true); // Set loading to true when data is being fetched
    
      //  setTimeout(() => { 
    axios.get(`${process.env.REACT_APP_LOCAL_BASE_URL}/v2/routes/${routeid}`,
   { headers:{'access-token' : user2['access-token'],'uid' :user2.uid , 'client': user2.client }})
   .then((res)=> {

    
        console.log(res.data.response.route);
        console.log( "WORKING :" ,process.env.NODE_ENV)
      checkAuthOfThisOrgOrGroup(res.data.response.route?.organisation_id,res.data.response.route?.groups)
      console.log(res.data.response.route?.groups);
      
   
     let newarrayone=(res.data.response.route?.axes).filter(checkAdult);
     function checkAdult(one) {
       return  one.id === axisid
     }
if(newarrayone.length==0){
  setIsLoading(false);
  //history.push("/zoning/zoningRoutes/Error");
  setGoToErrorComponent(true)
  setErrorComponentData({code:'#403',message:'AnErrorHappend'})
  toast.error("Axis ID Error ", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
    }) 
    
}else{
  setAllAxisData(newarrayone)
  console.log(newarrayone);
  setIsLoading(false);
}


    })
 
    .catch((err)=>{console.log("API ERROR");
    toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ioa3`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      })
      setIsLoading(false); 
     
  })
//}, 3000);
  }
   getAxisInfo();
   /**requstToGetAll*/
   const getTMSRouteIDAndAllData  =  () => {
     axios.get(`${process.env.REACT_APP_MID_LINK}/getTMSRouteID?code=${axiscode}`,

    { headers:{Authorization : 'ApiKey ' + "0bf99d2e76c84c55816a21026db92e18" }})
    .then((res)=> {
      const arrayN: any[] = [];
      console.log(res.data);
      console.log(res.data.routeList);
      
      for (let i = 0; i < res.data.routeList?.length; i++) {
        arrayN.push(res.data.routeList[i].routeVersion);
      }
      //[5,4]
      console.log(Math.max(...arrayN)); // 5
      
      const arrayB = (res.data.routeList as any[])?.filter(checkAdult2);
      //[{that have routeVersion=5}]
      function checkAdult2(one: any) {
        return one.routeVersion === Math.max(...arrayN);
      }
      
      console.log(arrayB);
      
   
      if(arrayB?.length==0){
        //setCheck(1);
        setLineStringSearch("EMPTY_ID")
        setTMSRouteData({geometry:"LINESTRING (31.233334 30.033333)",id:"EMPTY_ID",routeCode:"FAKE_CODE",routeVariant:"FAKE_Variant"})  
        setLineStringInfo([[30.033333,31.233334]])
     
      }else{
        setLineStringSearch(arrayB[0]?.id) // tms route id 
   
   
        
       /**requstToGetAll*/
   const getAllTMSRouteData  =  () => {

  
     axios.get(`${process.env.REACT_APP_MID_LINK}/getAllTMSRouteData?id=${arrayB[0]?.id}`,

    { headers:{Authorization : 'ApiKey ' + "0bf99d2e76c84c55816a21026db92e18" }})
    .then((res)=> { 
      /*if(){
        console.log(res.data); 
      } else{*/
      console.log(res.data);
      if(res.data==''){

setTMSRouteData({geometry:"LINESTRING (31.233334 30.033333)",id:"EMPTY_ID",routeCode:"FAKE_CODE",routeVariant:arrayB[0]?.routeVariant})  
setLineStringInfo([[30.044420 , 31.235712]])
setBoundsIsZones(1);
      }else{
        console.log(res.data);
        setTMSRouteData(res.data) //tms line string data without stops
        //setLineStringInfo(res.data);
        if (
          res.data?.geometry === null ||
          res.data?.geometry.trim().length === 0 ||
          res.data?.geometry.length === 1 ||
          res.data?.geometry.trim() === "LINESTRING ()" ||
          res.data?.geometry.trim() === "LINESTRING ( )"
        ) {
          setLineStringInfo([[30.044420, 31.235712]]);
          setBoundsIsZones(1);
          console.log("NOOOOOOOOOOOOOOOOOOOOOOOOOOO");
        } else {
          let arrayOfEach: [any, any][] = [];
          let beforeConvert = res.data?.geometry
            .replace("LINESTRING (", "")
            .replace(")", "")
            .trim()
            .replace(/ +/g, "**")
            .split(",");
        
          beforeConvert.forEach((rs: any, is: any) => {
            const lat = Number(rs.split("**")[1]);
            const long = Number(rs.split("**")[0]);
            arrayOfEach.push([lat, long]);
          });
        
          setLineStringInfo(arrayOfEach);
          console.log(arrayOfEach);
        }
        

      }

     // }

      }).catch((err)=>{
       console.log(err);
       toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ioa4`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        }) 
      })
    }

      getAllTMSRouteData();

   
  }
    
     })
  
     .catch((err)=>{console.log("API ERROR");
     toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ioa5`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
      }) 
    })
   }
   getTMSRouteIDAndAllData ();


   //alert(JSON.stringify({ lineStringSearch, TMSRouteData, lineStringInfo, axisid, axiscode, boundsIsZones,permissionValue }));
    
      },[])

 if(goToErrorComponent){
return (<ErrorComponent code={errorComponentData.code} message={errorComponentData.message}/>)
 }else{
  return (
    <div className='general addaxisinfosec '>    
       <div>
         </div>
        <div className='second mapcont' >
         <div className='Info-all ' style={{marginTop:"2px",padding:"4px",position:"relative"}}>
        <Link to={"/zoning/zoningRoutes/routes/"+routeid} style={currentLanguageCode == "en-us" ? {position:"absolute" , right:"20px",borderRadius:"15px",backgroundColor:"#dcdcdc",border:"1px solid #dcdcdc",padding:"0", top:"15px"}: {position:"absolute" , top:"0px",left:"20px",borderRadius:"15px",backgroundColor:"#dcdcdc",border:"1px solid #dcdcdc",padding:"0"}} className="btn btn-dark " >
        {currentLanguageCode == "en-us" ?<ChevronRightIcon/>  :<ChevronLeftIcon/> }
   </Link>
        {
              isLoading ? (
                <div style={{textAlign:"center",marginTop:"5px",padding:"7px"}}>
                    <img src={Loading} alt="loading"  style={{width:"60px"}} />
               </div>) : 
        allAxisData[0]?.id != undefined ? <>
   
{currentLanguageCode == "en-us" ? <h6>{intl.formatMessage({ id: 'NameCode'}) + " :  "}  <span className='value-info'>{allAxisData[0]?.ar_name + " / " + allAxisData[0]?.en_name  + ` ( ${allAxisData[0]?.code} ) `}</span>  </h6> : <h6>{intl.formatMessage({ id: 'NameCode'}) + " :  "}  <span className='value-info'>{allAxisData[0]?.en_name  + " / " +   allAxisData[0]?.ar_name + ` ( ${allAxisData[0]?.code} ) `  }</span>  </h6> }
     
        <h6>{intl.formatMessage({ id: 'BaseFare'}) + " :  "} <span className='value-info'>{ allAxisData[0]?.base_fare.cash_price + " / " + allAxisData[0]?.base_fare.card_price +  intl.formatMessage({ id: 'CuSym' })}</span> </h6>

       
</>: <div style={{textAlign:"center",padding:"15px"}}>{intl.formatMessage({ id: 'NoDateAvailable'})}</div>

}
        {lineStringSearch != 0  && user && user2 ? <OurMap  lineStringSearch={lineStringSearch} TMSRouteData={TMSRouteData} lineStringInfo={lineStringInfo} axisid={axisid} axiscode={axiscode} boundsIsZones={boundsIsZones} permissionValue={permissionValue}/> : <><h5 style={{margin:"12px",textAlign:"center",color:"#2891cd",padding:"10px"}}>{intl.formatMessage({ id: 'WaitMessage'})}</h5>
<div style={{textAlign:"center",marginTop:"20px"}}>
<img src={Loading} alt="loading"  style={{width:"400px"}} />
</div>


        </> }
 


    </div> </div></div>
    
  )
 }
 
}

export default InfoOfAxis
