// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { NotAuthorized } from 'app/components/not-authorized';
import { PageNotFound } from 'app/components/page-not-found';
import { SystemHeader, SystemIcon } from 'app/components/shared/system-header';
import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasReadAccessToMasterConfigSubSystem } from 'selectors';
import { CardsReports } from './cards-reports';
import { OperationsReports } from './operations-reports';
import { AFCReports } from './afc-reports';
const Reports: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);
  const intl = useIntl();
  const canViewAFCReports = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'AFCReports')
  );
  const canViewOperationsReports = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'OperationsReports')
  );
  const canViewCardsReports = useTypedSelector(state =>
    hasReadAccessToMasterConfigSubSystem(state, 'CardsReports')
  );
  const getInitialRoute = () => {
    if (canViewAFCReports) {
      return `${baseURL}/afcreports`;
    }
    if (canViewOperationsReports) {
      return `${baseURL}/oper-reports`;
    }
    if (canViewCardsReports) {
      return `${baseURL}/cardsreports`;
    }
    return `${baseURL}/afcreports`;
  };

  return (
    <>
      <SystemHeader
        headerTitle={intl.formatMessage({ id: 'Reports' })}
        headerIcon={SystemIcon.Reports}
      />

      <Switch>
        <Redirect from={baseURL} exact to={getInitialRoute()} />

        <Route path={`${baseURL}/afcreports`}>
          {canViewAFCReports ? <AFCReports /> : <NotAuthorized />}
        </Route>
        <Route path={`${baseURL}/oper-reports`}>
          {canViewOperationsReports ? <OperationsReports /> : <NotAuthorized />}
        </Route>
   
        <Route path={`${baseURL}/cardsreports`}>
          {canViewCardsReports ? <CardsReports /> : <NotAuthorized />}
        </Route>
        <Route path="/">
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

export { Reports };
