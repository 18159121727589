// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
 // import "../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box ,CircularProgress} from '@mui/material';
const TransactionTabs = ({tabsValue,handleChangeTabs}) => {
      const dispatch = useDispatch();
      const match = useRouteMatch();
      const intl = useIntl();

return(
    <Box  className='tabs-parent' sx={{fontSize:"15px",color:"white",fontFamily:"Cairo",padding:"1px", borderRadius:"6px", width: '94%',marginLeft:"30px",marginRight:"30px",marginBottom:"12px", bgcolor: '#363740' }}>
    <Tabs
      value={tabsValue}
      onChange={handleChangeTabs}
      centered
      sx={{
       '.MuiTabs-flexContainer': {
         justifyContent: 'space-around',

       },
       '.MuiTab-root': {
         width: '50%', // Set each tab to take half the width
         maxWidth: 'none', // Override default maxWidth
         fontSize:"12px",color:"white",fontFamily:"Cairo",
       },
       '.Mui-selected': { // Styles for the selected tab
         color: '#5C636A', // Use secondary color for the selected tab
         fontWeight: 'bold', // Make the font bold for selected tab
         backgroundColor:"white",
         borderRadius:"6px",
         fontSize:"13px",
         fontFamily:"Cairo"
       },
     }}
     indicatorColor="white"
     textColor="white"
   >
 <Tab className='trans-types-tabs-child' label={intl.formatMessage({ id: 'tickets' })} />
 <Tab className='trans-types-tabs-child' label={intl.formatMessage({ id: 'charging' })} />
 </Tabs>
 </Box>
   )
  
};

export { TransactionTabs };
