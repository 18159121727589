// @ts-nocheck
import React, { useRef } from 'react'
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import ExternalInfo from "components/ExternalInfo";
import { ToastContainer, toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { storeAllZones, storeZones } from "actions/zoning.actions";
import Cookies from 'js-cookie';
import { useIntl } from 'react-intl';
interface MyComponentProps {
  axisid: any;
  axiscode: any;
  tmsRouteId: any;
  tmsRouteCode: any;
  zoneId: any;
  zoneName: any;
  zoneCashPrice: any;
  zoneCardPrice: any;
  stopCode: any;
  stopsData: any;
  zoneGeometry: any;
}

const EditZoneModal: React.FC<MyComponentProps> =({axisid,axiscode,tmsRouteId,tmsRouteCode,zoneId,zoneName,zoneCashPrice,zoneCardPrice,stopCode,stopsData,zoneGeometry}) => {
  const intl = useIntl();
  let user: any = '';
  let user2 :any= '';
  let userRefresh:any = '';
  
  const authData = localStorage.getItem("authData");
  
  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    user = parsedAuthData.userData;
    user2 = parsedAuthData.userToken;
    userRefresh = parsedAuthData.userToken;
  }
  const allZones = useSelector (state => state.zoning.zones);
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
    const dispatch= useDispatch();
    const { t } = useTranslation()
    const newCashPrice = useRef();
  const newCardPrice = useRef();
    const editZone = (zoneId,zoneName,tmsRouteId,tmsRouteCode,fareAxisId
        ,fareAxisCode,stopCode,UID) => {
 
          console.log(newCashPrice.current.value);
          console.log(newCardPrice.current.value);
          console.log(stopsData);
          console.log(stopCode);
          
          if(newCashPrice.current.value.trim().length>5 || newCardPrice.current.value.trim().length>5){
            toast.error("Price Entered Is Very High", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              })
            }  else if(newCashPrice.current.value.trim().length==0 || newCardPrice.current.value.trim().length==0){
              toast.error("Empty Fields", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                })
              } 
          else{
      
         let slat = 0;
         let slng = 0;
         function get_polygon_centroid(pts) {
          var first = pts[0], last = pts[pts.length-1];
          if (first.x != last.x || first.y != last.y) pts.push(first);
          var twicearea=0,
          x=0, y=0,
          nPts = pts.length,
          p1, p2, f;
          for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
             p1 = pts[i]; p2 = pts[j];
             f = p1.x*p2.y - p2.x*p1.y;
             twicearea += f;          
             x += ( p1.x + p2.x ) * f;
             y += ( p1.y + p2.y ) * f;
          }
          f = twicearea * 3;
          return { x:x/f, y:y/f };
       }
       const executeEditZone = (finalStopCode,finalLat,finalLng) => {
      
         axios.post(

          `${process.env.REACT_APP_MID_LINK}/editZone?zoneId=${zoneId}&zoneName=${zoneName}&tmsRouteId=${tmsRouteId}&tmsRouteCode=${tmsRouteCode}&fareAxisId=${fareAxisId}&fareAxisCode=${fareAxisCode}&stopCode=${finalStopCode}&stopLatitude=${finalLat}&stopLongitude=${finalLng}

          &zoneCashPrice=${newCashPrice.current.value}&zoneCardPrice=${newCardPrice.current.value}&UID=${UID}`)
        .then((res)=> {  
          if(res.data != "Request failed with status code 400"){
            console.log(res.data);
            /*setZones(res.data.zones);*/
            dispatch(storeZones((res.data.zones)));
            toast.success("Edit Successfuly", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              }) 
          }else{
            toast.error(`Sorry , ${res.data} , Code : #ezm3`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
              }) 
          }
     
          }).catch((err)=>{
           console.log(err);
           toast.error(`Sorry , ${err} , Please Try Again Later Or Try Refresh Page , Code : #ezm4`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
            }) 
          }) }
         if(stopCode=="dynamic_stop"){
     
          console.log(get_polygon_centroid(zoneGeometry[0]));
          slat = get_polygon_centroid(zoneGeometry[0]).x
          slng = get_polygon_centroid(zoneGeometry[0]).y
          executeEditZone(stopCode,slat,slng)
         }else{
          console.log(stopsData);
          
          let arrayOfIwantedStop =  (stopsData)?.filter(function checkAdult22(one) {
            return  one.stopCode == stopCode
          })
       console.log(arrayOfIwantedStop[0]);
       if(arrayOfIwantedStop[0] != undefined){
       slat = arrayOfIwantedStop[0].lat;
       slng = arrayOfIwantedStop[0].lng;
       executeEditZone(stopCode,slat,slng)
       }else{ //stop of (zone based on stop) is deleted
        slat = get_polygon_centroid(zoneGeometry[0]).x
        slng = get_polygon_centroid(zoneGeometry[0]).y
        executeEditZone("dynamic_stop",slat,slng)
       }
         }
      }
      };
  return (
    <>
<button type="button" 
className="editBtn" data-bs-toggle="modal" data-bs-target={"#exampleModal"+ zoneId}>
< EditIcon/> 
</button>


<div className="modal fade" id={"exampleModal"+ zoneId} style={{zIndex:"9999969"}} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
     
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <h5>  {currentLanguageCode == "en-us" ? `Edit   ${zoneName}  Zone` : ("تعديل  نطاق   " + zoneName   )}</h5>
        <h6 className='head-filter'style={{margin:"4px"}}>{intl.formatMessage({ id: 'CashPrice' })}</h6>
                   <input type="number" ref={newCashPrice} defaultValue={zoneCashPrice} className="form-control" style={{margin:"2px"}} />
                   <h6 className='head-filter'style={{margin:"4px"}}>{intl.formatMessage({ id: 'CardPrice' })}</h6>
                   <input type="number" ref={newCardPrice} defaultValue={zoneCardPrice} className="form-control" style={{margin:"2px"}}  />

      </div>
      <div className="modal-footer">

        <button type="button"
        onClick={()=>editZone(zoneId,zoneName,tmsRouteId,tmsRouteCode,axisid,axiscode,stopCode,user2.uid)}
        className="btn btn-success"data-bs-dismiss="modal">{intl.formatMessage({ id: 'Save' })} </button>
      </div>
    </div>
  </div>
</div> </>
  )
}

export default EditZoneModal
