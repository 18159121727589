// @ts-nocheck
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { NotAuthorized } from 'app/components/not-authorized';
import { CardContainer } from 'app/components/shared/card-container';

import { trimTrailingSlash, useTypedSelector } from 'helpers';
import { hasWriteAccessToMasterConfigSubSystem } from 'selectors';
import AddMerchantPOS from './addMerchantPOS';
import EditMerchantPOS from './updateMerchantPOS';
import MerchantPOSTable from './merchantPOSTable';
import ViewMerchantPOS from './view-merchantPOS';

const MerchantPOS: React.FC = () => {
  const match = useRouteMatch();
  const baseURL = trimTrailingSlash(match.url);

  const hasMerchantPOSAddAccess = useTypedSelector(state =>
    hasWriteAccessToMasterConfigSubSystem(state, 'MerchantPOS')
  );

  return (
    <CardContainer>
      <Switch>
        <Route path={`${baseURL}/new`}>
          {hasMerchantPOSAddAccess ? <AddMerchantPOS /> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id/edit`}>
        {hasMerchantPOSAddAccess ? <EditMerchantPOS/> : <NotAuthorized />}
        </Route>

        <Route path={`${baseURL}/:id`}>
         <ViewMerchantPOS/>
        </Route>

        <Route exact path={`${baseURL}`}>
          <MerchantPOSTable/>
        </Route>
      </Switch>
    </CardContainer>
  );
};

export { MerchantPOS };
