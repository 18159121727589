// @ts-nocheck
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unknown-property */
/* eslint-disable prefer-template */
/* eslint-disable react/self-closing-comp */
/* eslint-disable import/no-unresolved */
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line radix
// eslint-disable-next-line jsx-a11y/click-events-have-key-events
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
//import { FilterChargingTransaction } from './filter-Logs';
import { useIntl } from "react-intl";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { basicButtonStyles } from "app/shared/styles";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
const useStyles = makeStyles(() =>
  createStyles({
    table: { 
      minWidth: 750, 
      overflow: 'scroll', 
      whiteSpace: 'nowrap' 
    },
    addButton: { 
      ...basicButtonStyles 
    },
    row: { 
      '&:nth-of-type(odd)': {
        backgroundColor: '#f9f9f9', // Light gray background for odd rows
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#ffffff', // White background for even rows
      },
      cursor: 'default', // Default cursor for table rows
    },
  })
);

const LogsTable: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState(true);
  const [mainApiResponseData, setMainApiResponseData] = useState<any[]>([]);

  const [dataCountNumber, setDataCountNumber] = useState<number | null>(null);


  const authData = localStorage.getItem("authData");
  let loggedInUserAccessTokenClientUid: any = '';
  const history = useHistory();
  const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us';

  if (authData !== null) {
    const parsedAuthData = JSON.parse(authData);
    loggedInUserAccessTokenClientUid = parsedAuthData.userToken;
  }
//console.log(loggedInUserAccessTokenClientUid);

  useEffect(() => {
    const makeRequest = () => {
      const url = `${process.env.REACT_APP_ABT_ALL_APIS_BASE_URL}/api/v1/transactions/get_backend_logs`;
      //const url = `http://localhost:3003/api/v1/transactions/get_backend_logs`;

      setIsLoading(true);



      axios.post(url, {},{
        headers: {
          'access-token': loggedInUserAccessTokenClientUid['access-token'],
          'uid': loggedInUserAccessTokenClientUid.uid,
          'client': loggedInUserAccessTokenClientUid.client
        }
      })
        .then((res) => {
          console.log(res.data.data.data);
          
          setMainApiResponseData(res.data.data.data);
          setDataCountNumber(res.data.data.count);
          //setPageCount(res.data.data.totalPages);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("API ERROR", err);
          //toast.error(`Sorry, ${err.message}. Please try again later.`);
          setIsLoading(false);
        });
    };

    makeRequest();
  }, []);

  return (
    <div className='dev-contain-all-page-content'>
      <h2 className='header-of-subsystem-page'>
        <PendingActionsIcon style={{ margin: "10px  10px 13px 10px", fontSize: "28px" }} />
        {intl.formatMessage({ id: 'Logs' })}
      </h2>

      <div className="Table-Wrapper" style={{  marginTop: "30px" }}>
        {isLoading ? (
          <div style={{ textAlign: "center", marginTop: "7px" }}>
            <CircularProgress style={{ width: "42px" }} />
          </div>
        ) : (
          <Table className={classes.table} size="small" aria-label="charging transactions table">
            <TableHead style={{ border: "0" }}>
              <TableRow style={{ border: "0" }}>
                <TableCell >{intl.formatMessage({ id: 'request' })}</TableCell>
                <TableCell >{intl.formatMessage({ id: 'response' })}</TableCell>
         
              </TableRow>
            </TableHead>
            <TableBody>
              {mainApiResponseData?.length > 0 ?
                mainApiResponseData.map((r) => {
                  let parsedRequestMessage;
                  try {
                   // const message = r.request.message;
                   const {message} = r.request ;
                    const headersStart = message.indexOf('Headers: {');
                    const bodyStart = message.indexOf('Body: {');
                
                    if (headersStart !== -1 && bodyStart !== -1) {
                      const headersEnd = message.indexOf('}', headersStart) + 1;
                      const bodyEnd = message.indexOf('}', bodyStart) + 1;
                
                      const headersJson = message.substring(headersStart + 9, headersEnd);
                      const bodyJson = message.substring(bodyStart + 6, bodyEnd);
                
                      const headers = JSON.parse(headersJson);
                      const body = JSON.parse(bodyJson);
                
                      parsedRequestMessage = {
                        headers,
                        body,
                        otherData: message.substring(0, headersStart).trim() // Include any other data before headers
                      };
                    } else {
                      parsedRequestMessage = message;
                    }
                  } catch (error) {
                    console.error('Error parsing request message:', error);
                    parsedRequestMessage = r && r.request && r.request.message ? r.request.message : intl.formatMessage({ id: 'NoDateAvailable' }); // Fallback to original message if parsing fails
                  }
                  return (
                  <TableRow className={classes.row} key={r.ct_id}>
                    <TableCell  style={{overflowY:"scroll",textAlign:"left",direction:"ltr"}}> <div style={{overflow:"none",width:"600px",height:"300px"}}> <pre> <code style={{textAlign:"left",direction:"ltr"}}>{r && r.request && r.request.message && r.request.timestamp ?  JSON.stringify(({time:r.request.timestamp, data:parsedRequestMessage}), null, 2) : <center>{intl.formatMessage({ id: 'NoDateAvailable' })}</center> }</code></pre></div> </TableCell>
                    <TableCell style={{overflowY:"scroll",textAlign:"left",direction:"ltr"}}> <div style={{overflow:"none",width:"600px",height:"300px"}}> 
                      <pre><code style={{textAlign:"left",direction:"ltr"}}>
                      {r && r.response && r.response.message && r.response.timestamp ?JSON.stringify({time:r.response.timestamp, data:r.response.message}, null, 2) : <center>{ intl.formatMessage({ id: 'NoDateAvailable' })}</center> }
                      
                      </code></pre></div> </TableCell>
                  </TableRow>
)}) : (
                  <TableRow>
                    <TableCell colSpan={10} style={{ textAlign: "center", padding: "15px" }}>{intl.formatMessage({ id: 'NoDateAvailable' })}</TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

export default LogsTable;

