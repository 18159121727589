// @ts-nocheck
/* eslint-disable padding-line-between-statements, import/order, import/newline-after-import, spaced-comment */
/* eslint-disable @typescript-eslint/no-unused-vars, react/jsx-newline, react/jsx-no-bind, no-else-return */
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define, no-lonely-if, no-nested-ternary, import/no-extraneous-dependencies */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-newline */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-nested-ternary */

import { useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
  import React, { Fragment, useEffect, useRef, useState } from 'react'
  import { Suspense } from 'react'
  import "../../style.css";
import { useIntl } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
const TableControlDataView = ({page,pageCount,limit,handlePrevious,handleNext,handleChangeLimit}) => {
      const dispatch = useDispatch();
      const match = useRouteMatch();
      const intl = useIntl();
      const currentLanguageCode = localStorage.getItem("EASY_MOBILITY_LANGUAGE") || 'en-us' //	ar-eg
return(
<div style={{direction:"ltr"}} className='pagination-limit-table-info-part'> 


{ currentLanguageCode == "en-us"? <span style={{margin:"10px",direction:"ltr"}}>
{page}  <span className='show-span'><bdi style={{margin:"10px"}}> {intl.formatMessage({ id: 'From' })} </bdi></span>  {pageCount} </span>

: <span style={{margin:"10px",direction:"ltr"}}>
{ pageCount}  <span className='show-span'><bdi style={{margin:"10px"}}> {intl.formatMessage({ id: 'From' })} </bdi></span>  {page} </span>}
<button className='pageArrow'  disabled={page === 1} onClick={handlePrevious}>
<span style={{direction:"ltr"}}>   
<KeyboardArrowLeftIcon style={{fontSize:"30px"}}/></span>
</button>
<button className='pageArrow' disabled={page === pageCount} onClick={handleNext}>
<span style={{direction:"ltr"}}>   
<KeyboardArrowRightIcon style={{fontSize:"30px"}}/></span>

</button>
<span style={{margin:"10px",direction:"ltr"}}><bdi>
<span className='show-span'style={{margin:"10px",direction:"ltr"}}> <bdi>
{intl.formatMessage({ id: 'Show' })}  </bdi></span>
<select className='show-select'style={{direction:"ltr"}} onChange={(e)=>handleChangeLimit(e.target.value)}>
<option value="10"selected={limit ==10 ? true :false}>10</option>
<option value="15"selected={limit ==15 ? true :false}>15</option>
<option value="25"selected={limit ==25 ? true :false}>25</option>
<option value="50"selected={limit ==50 ? true :false}>50</option>
<option value="100"selected={limit ==100 ? true :false}>100</option>
<option value="150"selected={limit ==150 ? true :false}>150</option>
</select></bdi>
</span>


  </div>
   )
  
};

export { TableControlDataView };
